import { renderBrickIcon } from '@amedia/brick-icon/template';

import esc from '../../utils/escape-html';

import {
  breakingPulseStyle,
  breakingTextStyle,
  breakingElement,
} from './styles';
import type { BreakingNewsText } from './types';
import { mapToBreakingSkin } from './utils';

export const breakingTextTemplate = (
  data: BreakingNewsText,
  isServerSide: boolean
) => {
  const { text, color = 'red', disableSonar = false, theme, skin } = data;

  const defaultTextMarkup = text
    ? `<span class="${breakingTextStyle({ skin })}">${text}:</span>`
    : '';

  const defaultPulse = disableSonar
    ? ''
    : `
    <span class="${breakingPulseStyle({ skin })} breaking-news-icon-text-${esc(
        color
      )}" itemprop="breaking-news">${renderBrickIcon({
        dataIconId: 'pill-breaking',
        dataIconText: 'Nyhetsvarsel',
      })}</span>`;

  const structureTemplate = {
    default: `${defaultPulse}${defaultTextMarkup}`,
  };

  const markup = structureTemplate[theme] || structureTemplate.default;

  if (isServerSide) {
    return `<breaking-text data-theme="${theme}" class="${breakingElement}" is-rendered>${markup}</breaking-text>`;
  }

  return markup;
};

export function renderBrickBreakingNewsText(data: BreakingNewsText) {
  const skin = mapToBreakingSkin(data.skin);
  return breakingTextTemplate({ ...data, skin }, true);
}
