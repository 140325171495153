import { css } from '../../styles';

// The object overlayLayout are used to generate css for inline injection by scorponok
export const overlayLayout = {
  variants: {
    version: {
      imageLeft: {
        display: 'none',
        '&.mirror': {
          left: 'unset',
        },
        '&:not(.opinion &)[data-theme="nettavisen"]': {
          display: 'block',
          '& brick-pillbox': {
            display: 'none',
          },
        },
        '&[data-theme="alfa"], &[data-theme="charlie"]': {
          display: 'block',
          '& brick-pillbox': {
            display: 'none',
          },
        },
      },
      default: {},
    },
  },
};

export const overlayStyle = css({
  '--brick-teaser-display-overlay-pills': 'flex',
  display: 'var(--brick-teaser-display-overlay-pills)',
  gap: '$x1',
  position: 'absolute',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  bottom: 0,
  left: 0,
  right: 0,
  padding: '$teaserBoxInsetM',
  '& .premium-overlay': {
    flex: '1',
    order: 1,
    textAlign: 'right',
  },
  '& brick-pill': {
    order: 0,
  },
  '& brick-pillbox': {
    padding: '$none',
    flexShrink: '1',
  },
  '& .spillsenter-overlay': {
    background: '$baseInvertedBg',
    opacity: '$80',
    bottom: 0,
    color: '$baseInvertedFg',
    fontFamily: '$1',
    fontSize: '12px',
    height: 'auto',
    padding: '10px',
    left: 0,
    textAlign: 'left',
    width: '100%',
    zIndex: 1,
  },
  '@bp1': {
    '& .spillsenter-overlay': {
      padding: '1vw',
      fontSize: '1.2vw',
    },
  },
  '@bp2': {
    '& .spillsenter-overlay': {
      padding: '2vw',
      fontSize: '2.5vw',
    },
  },
  '@bp532': {
    padding: '$teaserBoxInsetL',
  },

  variants: {
    version: {
      imageLeft: {
        ...overlayLayout.variants.version.imageLeft,
      },
      default: {},
    },
    marker: {
      true: {
        padding: 0,
        '& .premium-overlay': {
          padding: '$teaserBoxInsetM',
        },
        '@bp532': {
          padding: 0,
          '& .premium-overlay': {
            padding: '$teaserBoxInsetL',
          },
        },
      },
      false: {
        'brick-carousel &': {
          padding: '$x2s',
          '@bp532': {
            padding: '$x2',
          },
        },
      },
    },
    teaserType: {
      teaser: {},
      story: {},
      review: {},
      opinion: {},
      commercial: {},
      video: {},
      gallery: {},
      alt: {},
      sport: {},
      feature: {},
      untold: {},
    },
  },
  defaultVariants: {
    version: 'default',
    marker: false,
    theme: '',
  },
  compoundVariants: [
    {
      theme: 'nettavisen',
      teaserType: 'opinion',
      version: 'imageLeft',
      css: {
        display: 'flex',
        boxSizing: 'border-box',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
        maxWidth: '100%',
        '& .premium-overlay': {
          flex: 'unset',
        },
      },
    },
  ],
  '.solo-group &': {
    '& brick-pillbox': {
      display: 'none',
    },
    '& .body .top-pills': {
      display: 'block',
    },
  },
});
