import { CommercialCategory, TeaserType } from '../types';

interface Props {
  teaserType?: TeaserType;
  sponsored: boolean;
  url: string | null;
}

export const mapToCommercialCategory = ({
  teaserType,
  sponsored,
  url,
}: Props): CommercialCategory | undefined => {
  switch (true) {
    case isCommercial(teaserType, sponsored):
      return 'commercial';
    case !url || url.includes('direktesport.no'):
      return;
    case isBetting(url):
      return 'betting';
    case isShopping(url):
      return 'shopping';
    default:
      return;
  }
};

function isCommercial(
  teaserType: TeaserType | undefined,
  sponsored: boolean
): boolean {
  return teaserType === 'commercial' || sponsored;
}

function isBetting(url: string | null): boolean {
  return (
    !!url &&
    ['/sportspill/', '/norsk-tipping.', '/trav/'].some((keyword) =>
      url?.includes(keyword)
    )
  );
}

function isShopping(url: string | null): boolean {
  return (
    !!url &&
    ['/shoppingtips/', 'reisetips.', '/reisetips/'].some((keyword) =>
      url?.includes(keyword)
    )
  );
}
