import { Design } from '@amedia/brick-tokens';

import getPremiumIcon from '../../components/common/premiumIcon';
import { PremiumVersion, Teaser } from '../../types';
import esc from '../../utils/escape-html';
import { noImageTopPillsStack } from '../../components/pills/noImageTopPillsStack';
import noImageBottomPillStack from '../../components/pills/noImageBottomPillStack';
import tipUsTemplate from '../../components/footer/tip-us';
import { footerContainerStyle } from '../../components/footer/footer-style.js';
import { titleContainerStyle } from '../../components/title/title-style';
import getTemplateStructure from '../../components/title/templates/structure';
import {
  noImageStyle,
  noImageTopPillContainer,
  noImageBottomPillContainer,
  containerStyle,
} from '../no-image-teaser-style';

import countdownTemplate from '../../components/countdown/countdown';

interface ComponentTemplate {
  data: Teaser;
  useAutolayout: boolean;
  aoiBeta: boolean;
}

function getTopPillsMarkup(body, theme) {
  const { pills, premiumVersion } = body;
  const styles = noImageTopPillContainer();

  const topPills =
    theme === 'nettavisen'
      ? noImageTopPillsStack(pills, premiumVersion)
      : noImageTopPillsStack(pills);
  const topPillsMarkup = topPills
    ? `<div class="${styles}">
  ${topPills}
  </div>`
    : '';
  return topPillsMarkup;
}

function getPremiumBadge({
  theme,
  premiumVersion,
  topPillsMarkup,
}: {
  theme: Design;
  premiumVersion?: PremiumVersion;
  topPillsMarkup?: string;
}) {
  if (topPillsMarkup?.includes('plus')) {
    return '';
  }
  const filled =
    premiumVersion === 'plusall' ||
    theme === 'alfa' ||
    theme === 'nettavisen' ||
    false;

  const premiumBadge = premiumVersion
    ? getPremiumIcon({ theme, premiumVersion, filled })
    : '';
  return premiumBadge;
}

export const getNoImageTemplateStructure: (
  componentTemplate: ComponentTemplate
) => string = ({ data, useAutolayout }: ComponentTemplate) => {
  const { body, theme, footer } = data;
  const {
    url,
    version,
    mirror,
    marker,
    premiumVersion,
    teaserType,
    backgroundColor,
    pills,
    title,
  } = body;
  const { skin, breakingNews, rating } = title;
  const mirrorClass = mirror ? 'mirror' : '';
  const markerClass = marker ? 'marker' : '';
  const tipUsMarkup = tipUsTemplate(footer);

  const footerStyles = footerContainerStyle({
    skin,
    theme,
  });

  const tipusFooterMarkup = tipUsMarkup
    ? `
    <footer class="${footerStyles}">
      ${tipUsMarkup}
    </footer>`
    : '';

  const isBreaking = breakingNews?.breakingNews;
  const themeStructure = getTemplateStructure(title, useAutolayout);

  const topPillsMarkup = getTopPillsMarkup(body, theme);
  const titleContainerStyles = titleContainerStyle({
    version,
    image: false,
    teaserType,
    theme,
    marker,
    isBreaking,
    rating,
  });

  const bottomPills =
    theme !== 'nettavisen' ? noImageBottomPillStack(pills) : '';

  const bottomPillsMarkup = bottomPills
    ? `<div class="${noImageBottomPillContainer({
        marker,
      })}">
    ${bottomPills}
</div>`
    : '';
  const premiumBadge = getPremiumBadge({
    theme,
    premiumVersion,
    topPillsMarkup,
  });

  const countdownMarkup = countdownTemplate(data);

  const teaserLayoutStyles = noImageStyle({
    teaserType,
    theme,
    topPills: !!topPillsMarkup,
    bottomPills: !!bottomPillsMarkup,
    premium: !!premiumBadge,
    marker,
  });

  return `
    <a href="${url}" itemprop="url" class="teaser_body ${containerStyle} ${esc(
    backgroundColor
  )} ${teaserLayoutStyles} ${mirrorClass} ${markerClass}">
      ${topPillsMarkup}    
      <div class="title_container ${titleContainerStyles}">
        ${themeStructure}
      </div>
      ${bottomPillsMarkup}
      ${premiumBadge}
    </a>
    ${countdownMarkup}
    ${tipusFooterMarkup}`;
};
