import { Image, Skin } from '../../types';
import overlayTemplate from '../overlay/overlay';
import { aoiCssVar } from '../../utils/getImageData.js';
import { getImageTag } from '../../utils/getImageTag';
import { bettingOverlayTemplate } from '../betting/betting';
import { getRatio } from '../../utils/getRatio';

import { imageStyle, figureStyle, imageWrapperStyle } from './image-style.js';
import { skinColorStyle } from '../../teaserTypes/skinStyles';

export default function teaserImageTemplate({
  data,
  useAutolayout = false,
  aoiBeta = false,
  disableOverlay = false,
  skin,
}: {
  data: Image;
  useAutolayout?: boolean;
  aoiBeta?: boolean;
  disableOverlay?: boolean;
  skin?: Skin;
}) {
  if (!data.imageData) {
    return '';
  }

  const {
    imageData,
    teaserType,
    version,
    overlay,
    title = '',
    marker,
    theme,
    commercialCategory,
  } = data;

  const imageRatio = getRatio(imageData);
  const imagePadding = imageRatio ? `padding-top:${imageRatio}%` : '';
  const imageTag = getImageTag({
    imageData,
    title,
    useAutolayout,
    teaserType,
  });
  const preview = imageData.preview ? true : false;
  const video = !!imageData.video;
  const markerClass = marker ? 'marker' : '';
  const styles = imageStyle({
    theme,
    version,
    preview: preview || video,
    aoiBeta,
  });

  const overlayMarkup = !disableOverlay ? overlayTemplate(overlay) : '';

  if (aoiBeta && !useAutolayout) {
    const { x_aoi, y_aoi } = aoiCssVar({
      aoiX: imageData.imgAoi?.x,
      aoiY: imageData.imgAoi?.y,
      aoiWidth: imageData.imgAoi?.width,
      aoiHeight: imageData.imgAoi?.height,
      imageWidth: imageData.imgAoi?.orgWidth,
      imageHeight: imageData.imgAoi?.orgHeight,
    });
    //Work in progress-todo preview animation, move vars to brick-teaser element
    return `
    <figure style="--focus-x:${x_aoi}%; --focus-y:${y_aoi}%;" class="teaser_image ${markerClass} ${figureStyle} ${styles}" data-theme="${theme}">
      <div class="imagewrapper ${imageWrapperStyle}">
          ${imageTag}
        ${overlayMarkup}
      </div>
    </figure>
    `;
  }

  //VI må sjekke noe annet - isBettingClassifed
  const renderBettingOverlay = commercialCategory === 'betting';

  return `
    <figure class="teaser_image ${markerClass} ${styles} ${skinColorStyle({
    skin,
    image: true,
  })}" data-theme="${theme}">
      <div class="imagewrapper" style="${imagePadding}">
        <div>
          ${imageTag}
        </div>
        ${renderBettingOverlay ? bettingOverlayTemplate() : ''}    
        ${overlayMarkup}
      </div>
    </figure>
    `;
}
