import { css } from '../../styles';

//the object bodyLayout are used to generate css for inline injection by scoponok
export const bodyLayout = {
  variants: {
    version: {
      imageLeft: {
        columnGap: '$teaserStackAM',
        display: 'grid',
        gridTemplateColumns: 'calc(34% + 3%) 1fr',
        gridFlow: 'row',
        position: 'relative',
        '& figure': {
          gridColumn: '1/1',
        },
        '& .body': {
          //x- and y-axis tokens have switched places on the horizontal teaser
          padding: '$teaserContentXInsetM $teaserContentYInsetM',
          boxSizing: 'border-box',
          gridColumn: '2/-1',
        },
        '[data-theme="alfa"] &': {
          '& .body .premium-icon': {
            display: 'none',
          },
        },
        '&.mirror': {
          gridTemplateColumns: '1fr calc(34% + 3%)',
          '& figure': {
            gridColumn: '2/-1',
            gridRow: '1/1',
          },
          '& .body': {
            gridColumn: '1/1',
          },
        },
        '&.marker': {
          gap: 0,
          columnGap: 0,
        },
        '&.marker .body': {
          gap: 0,
        },
        '&.mirror.marker .body': {
          paddingRight: 0,
        },
        '@bp532': {
          columnGap: '$teaserStackAL',
          '& .body': {
            //x- and y-axis tokens have switched places on the horizontal teaser
            padding: '$teaserContentXInsetL $teaserContentYInsetL',
            gridRowGap: '$teaserStackBL',
          },
          '&.mirror .body': {
            paddingRight: '$teaserBoxInsetL',
          },
          '&.marker': {
            columnGap: 0,
          },
          '&.marker .body': {
            gap: 0,
          },
          '&.mirror.marker .body': {
            paddingRight: 0,
          },
        },
        '.themeCharlie &': {
          '& .body': {
            paddingRight: '0',
            paddingLeft: '0',
          },
          '@bp532': {
            '& .body': {
              //x- and y-axis tokens have switched places on the horizontal teaser
              paddingRight: '-$teaserContentYInsetL',
              paddingLeft: '$teaserContentYInsetL',
            },
          },
        },
      },
    },
  },
};

export const baseBodyStyle = css({
  textDecoration: 'none',
  color: 'inherit',
  overflow: 'visible',
  boxSizing: 'border-box',

  '& > *': {
    minWidth: '0px', // setting this to avoid minwidth auto
  },

  '& brick-pillbox': {
    flexWrap: 'nowrap',
  },
});

export const bodyStyle = css({
  display: 'grid',
  gridTemplateColumns: '1fr',
  gridTemplateRows: 'auto',
  gridAutoRows: '1fr auto',
  textDecoration: 'none',
  color: 'inherit',
  overflow: 'visible',
  rowGap: '$teaserStackAM',
  '& .body': {
    container: 'teaser-content / inline-size',
    display: 'grid',
    gridTemplateRows: 'auto',
    gridTemplate: 'auto / 1fr',
    columnGap: '0',
    rowGap: '$teaserStackBM',
    padding: '$teaserContentYInsetM $teaserContentXInsetM',
  },
  '& .top-pills': { display: 'var(--brick-teaser-display-top-pills)' },
  '& .body > *': {
    minWidth: '0px', // setting this to avoid minwidth auto
  },
  '& .body brick-pillbox': {
    flexWrap: 'nowrap',
  },
  '@bp532': {
    rowGap: '$teaserStackAL',
    '& .body': {
      rowGap: '$teaserStackBL',
      padding: '$teaserContentYInsetL $teaserContentXInsetL',
    },
  },
  //Spacing when brick-carousel is parent
  'brick-carousel &': {
    '--brick-space-teaserStackAL': '0',
    '--brick-space-teaserStackAM': '0',
    '& .body': {
      '--brick-space-teaserContentYInsetL': '$space$x2',
      '--brick-space-teaserContentXInsetL': '$space$x2',
      '--brick-space-teaserContentYInsetM': '$space$x2s',
      '--brick-space-teaserContentXInsetM': '$space$x2s',
      flexGrow: '1',
    },
  },
  //Layout when solo-group is parent
  '[data-group="ghost"] .solo-group &': {
    '&.marker': {
      '--brick-space-teaserStackAM': 0,
      '--brick-space-teaserStackAL': 0,
    },
    gridTemplateColumns: '1fr',
    columnGap: 'var(--brick-space-teaserStackAM)',
    '&:has(figure), .has-image &': {
      '--brick-space-teaserStackCL': '$space$teaserStackAM',
      gridColumn: '2 / -1',
      gridTemplateColumns: '30% 1fr',
      '& .body .top-pills': {
        display: 'block',
      },
      '&.mirror': {
        gridTemplateColumns: '1fr 30%',
        '& figure': {
          gridColumn: '2/-1',
          gridRow: '1/1',
        },
        '& .body': {
          gridColumn: '1/1',
        },
      },
    },
  },
  variants: {
    teaserType: {
      teaser: {},
      story: {},
      review: {},
      opinion: {},
      commercial: {},
      video: {},
      gallery: {},
      alt: {},
      sport: {},
      feature: {},
      untold: {},
    },
    version: {
      imageLeft: {
        ...bodyLayout.variants.version.imageLeft,
      },
    },
    marker: {
      true: {
        gap: 0,
        '& .body': {
          padding: 0,
          gap: 0,
          justifyContent: 'flex-start',
        },
        '@bp532': {
          gap: 0,
          '& .body': {
            padding: 0,
            gap: 0,
            columnGap: 0,
          },
        },
      },
    },
  },
  defaultVariants: {
    version: 'default',
    marker: false,
  },
  '[data-group="ghost"] &': {
    '@container (inline-size >= 920px)': {
      display: 'grid',
      gap: '$teaserStackAL',
      gridTemplateColumns: '1fr',
      '&:has(figure)': {
        gridTemplateColumns: '1fr 2fr',
      },
    },
  },
});

export const pillContainer = css({
  placeItems: 'end',
  display: 'flex',
  justifyContent: 'space-between',
  paddingTop: 'calc($teaserStackCM - $teaserStackBM)',
  '@bp532': {
    paddingTop: 'calc($teaserStackCL - $teaserStackBL)',
  },
  '& [data-version="plus"]': {
    display: 'flex',
    justifyContent: 'flex-end',
    flexGrow: '1',
  },
  variants: {
    pills: {
      true: {
        alignItems: 'center',
        height: 'fit-content',
        alignSelf: 'end',
      },
      false: {},
    },
    marker: {
      true: {
        paddingTop: 0,
        '@bp532': {
          paddingTop: 0,
        },
      },
    },
    theme: {
      alfa: {},
      bravo: {},
      charlie: {},
      nettavisen: {},
      alt: {},
    },
  },
  deafaultVariants: {
    marker: false,
    pills: false,
  },
  compoundVariants: [
    {
      theme: 'charlie',
      pills: false,
      css: {
        gridColumn: '2',
      },
    },
    {
      theme: 'charlie',
      pills: true,
      css: {
        gridColumn: '1 / 3',
      },
    },
  ],
});
