import { css } from '@amedia/brick-tokens';

export const skinColorStyle = css({
  '@property --b-teaser-color-bg': `{
    syntax: "<color>";
    inherits: true;
    initial-value: 'inherit';
  }`,
  '@property --b-teaser-color-fg': `{
    syntax: "<color>";
    inherits: true;
    initial-value: 'inherit';
  }`,
  '--_teaser-skin-bg': 'inherit',
  '--_teaser-skin-fg': 'inherit',

  '--_teaser-color-bg': 'var(--b-teaser-color-bg, var(--_teaser-skin-bg))',
  '--_teaser-color-fg': 'var(--b-teaser-color-fg, var(--_teaser-skin-fg))',

  backgroundColor: 'var(--_teaser-color-bg)',
  color: 'var(--_teaser-color-fg)',

  '& .feature_gradient_overlay': {
    color: 'var(--_teaser-color-bg)',
  },

  variants: {
    skin: {
      none: {},
      black: {
        '--_teaser-skin-bg': '$colors$supportiveBlackBg',
        '--_teaser-skin-fg': '$colors$supportiveBlackFg',
      },
      'custom-one': {
        '--_teaser-skin-bg':
          'var(--custom-background-color-one, $colors$supportiveCustomOneBg)',
        '--_teaser-skin-fg':
          'var(--custom-background-color-one-front, $colors$supportiveCustomOneFg)',
      },
      'custom-two': {
        '--_teaser-skin-bg':
          'var(--custom-background-color-two, $colors$supportiveCustomTwoBg)',
        '--_teaser-skin-fg':
          'var(--custom-background-color-two-front, $colors$supportiveCustomTwoFg)',
      },
      'custom-three': {
        '--_teaser-skin-bg':
          'var(--custom-background-color-three, $colors$supportiveCustomThreeBg)',
        '--_teaser-skin-fg':
          'var(--custom-background-color-three-front, $colors$supportiveCustomThreeFg)',
      },
      highlight: {
        '--_teaser-skin-bg': '$colors$supportiveHighlightBg',
        '--_teaser-skin-fg': '$colors$supportiveHighlightFg',
      },
      opinion: {
        '--_teaser-skin-bg':
          'var(--opinion-background-color, $colors$supportiveOpinionBg)',
        '--_teaser-skin-fg':
          'var(--opinion-color-front, $colors$supportiveOpinionFg)',
      },
      sport: {
        '--_teaser-skin-bg': '$colors$supportiveSportBg',
        '--_teaser-skin-fg': '$colors$supportiveSportFg',
      },
    },
    image: {
      true: {},
      false: {},
    },
    teaserType: {
      teaser: {},
      story: {},
      review: {},
      opinion: {},
      commercial: {},
      video: {},
      gallery: {},
      alt: {},
      sport: {},
      feature: {},
      untold: {},
    },
  },
  compoundVariants: [
    {
      skin: 'none',
      image: true,
      css: {
        '--_teaser-skin-fg': '$colors$supportiveNoneFg',
        '--_teaser-skin-bg': '$colors$supportiveNoneBg',
        '&.feature': {
          '--_teaser-skin-fg': '#000',
          '--_teaser-skin-bg': '#fff',
        },
      },
    },
    {
      skin: 'none',
      image: false,
      css: {
        '--_teaser-skin-fg': '$colors$supportiveNoneFg',
        '--_teaser-skin-bg': '$colors$teaserNoImageBg',
        '&.feature': {
          '--_teaser-skin-fg': '#000',
          '--_teaser-skin-bg': '#fff',
        },
        '&.feature.themeNettavisen': {
          '--_teaser-skin-fg': '$colors$supportiveNoneFg',
          '--_teaser-skin-bg': '$colors$teaserNoImageBg',
        },
      },
    },
    {
      teaserType: 'feature',
      skin: 'black',
      css: { '--_teaser-skin-bg': '#000', '--_teaser-skin-fg': '#fff' },
    },
  ],
});
