import { css } from '@amedia/brick-tokens';

export const imageFeatureLayoutStyle = css({
  display: 'grid',
  gridTemplateColumns: '1fr',
  gridTemplateRows: 'auto',
  gridAutoRows: '1fr auto',
  gridTemplateAreas: '"image" "title"',
  variants: {
    premium: {
      true: { gridTemplateAreas: '"image image" "title title" ". premium"' },
      false: { gridTemplateAreas: '"image" "title"' },
    },
  },
});

export const noImageFeatureLayoutStyle = css({
  display: 'grid',
  gridTemplateRows: 'auto',
  gridAutoRows: '1fr auto',
  rowGap: '$teaserStackBM',
  columnGap: '$teaserStackBM',
  gridTemplateColumns: '1fr',

  padding: '$teaserContentYInsetM $teaserContentXInsetM',
  '@bp532': {
    padding: '$teaserContentYInsetL $teaserContentXInsetL',
    rowGap: '$teaserStackBL',
    columnGap: '$teaserStackBL',
  },

  variants: {
    theme: {
      alfa: {},
      bravo: {},
      charlie: {},
      nettavisen: {},
      alt: {},
    },
    pills: {
      true: {},
      false: {},
    },
    premium: {
      true: {},
      false: {},
    },
  },
  compoundVariants: [
    {
      pills: false,
      premium: false,
      css: {
        gridTemplateAreas: '"title"',
      },
    },
    {
      pills: true,
      premium: false,
      css: {
        gridTemplateAreas: '"pills" "title"',
        '[data-group="ghost"] .solo-group &': {
          gridTemplateAreas: '"pills" "title"',
        },
      },
    },
    {
      pills: false,
      premium: true,
      theme: 'alfa',
      css: {
        gridTemplateAreas: '"title premium"',
        '[data-group="ghost"] .solo-group &': {
          gridTemplateAreas: '"title premium"',
        },
      },
    },
    {
      pills: true,
      premium: true,
      theme: 'alfa',
      css: {
        gridTemplateAreas: '"pills ." "title premium"',
        '[data-group="ghost"] .solo-group &': {
          gridTemplateAreas: '"pills ." "title premium"',
        },
      },
    },
    {
      pills: false,
      premium: true,
      theme: 'bravo',
      css: {
        gridTemplateAreas: '"title title" ". premium"',
        '[data-group="ghost"] .solo-group &': {
          gridTemplateAreas: '"title premium"',
        },
      },
    },
    {
      pills: true,
      premium: true,
      theme: 'bravo',
      css: {
        gridTemplateAreas: '"pills pills" "title title" ". premium"',
        '[data-group="ghost"] .solo-group &': {
          gridTemplateAreas: '"pills .""title premium"',
        },
      },
    },
    {
      pills: false,
      premium: true,
      theme: 'charlie',
      css: {
        gridTemplateAreas: '"title premium"',
        '[data-group="ghost"] .solo-group &': {
          gridTemplateAreas: '"title premium"',
        },
      },
    },
    {
      pills: true,
      premium: true,
      theme: 'charlie',
      css: {
        gridTemplateAreas: '"pills ." "title premium"',
        '[data-group="ghost"] .solo-group &': {
          gridTemplateAreas: '"pills ." "title premium"',
        },
      },
    },
    {
      pills: false,
      theme: 'nettavisen',
      css: {
        gridTemplateAreas: '"title"',
        '[data-group="ghost"] .solo-group &': {
          gridTemplateAreas: '"title"',
        },
      },
    },
    {
      pills: true,
      theme: 'nettavisen',
      css: {
        gridTemplateAreas: '"pills" "title"',
        '[data-group="ghost"] .solo-group &': {
          gridTemplateAreas: '"pills title"',
        },
      },
    },
  ],
});

export const featureStyle = css({
  container: 'teaser-content / inline-size',
  color: 'inherit',
});

export const pillContainer = css({
  paddingBottom: '2px',
  variants: {
    theme: {
      alfa: {},
      bravo: {},
      charlie: {},
      nettavisen: {},
      alt: {},
    },
    hasImage: {
      true: { gridArea: 'image', zIndex: '2', alignSelf: 'end' },
      false: { gridArea: 'pills' },
    },
  },
  compoundVariants: [
    {
      theme: 'alfa',
      hasImage: true,
      css: {
        paddingLeft: '$teaserXInsetM',
        '@bp532': {
          paddingLeft: '$teaserXInsetL',
        },
      },
    },
    {
      theme: 'bravo',
      hasImage: true,
      css: {
        paddingLeft: '$teaserContentXInsetM',
        '@bp532': {
          paddingLeft: '$teaserContentXInsetL',
        },
      },
    },
    {
      theme: 'charlie',
      hasImage: true,
      css: {
        paddingLeft: '$teaserXInsetM',
        '@bp532': {
          paddingLeft: '$teaserXInsetL',
        },
      },
    },
    {
      theme: 'nettavisen',
      hasImage: true,
      css: {
        paddingLeft: '$teaserXInsetM',
        '@bp532': {
          paddingLeft: '$teaserSkinInsetL',
        },
      },
    },
  ],
  'brick-carousel &': {
    paddingLeft: '$x2s',
    '@bp532': {
      paddingLeft: '$x2',
    },
  },
});

export const titleContainer = css({
  gridArea: 'title',
  display: 'flex',
  flexDirection: 'column',

  gap: '$teaserStackTitleM',
  '@bp532': {
    gap: '$teaserStackTitleL',
  },
  variants: {
    theme: {
      alfa: {},
      bravo: {},
      charlie: {},
      nettavisen: {},
      alt: {},
    },
    hasImage: {
      true: { paddingTop: '6px' },
      false: {},
    },
    premium: {
      true: {},
      false: { paddingBottom: '$teaserYInsetM' },
    },
  },
  compoundVariants: [
    {
      theme: 'alfa',
      premium: false,
      css: {
        paddingBottom: '$teaserYInsetM',
      },
    },
    {
      theme: 'bravo',
      premium: false,
      css: {
        paddingBottom: '$teaserContentYInsetM',
      },
    },
    {
      theme: 'charlie',
      premium: false,
      css: {
        paddingBottom: '$teaserYInsetM',
      },
    },
    {
      theme: 'nettavisen',
      premium: false,
      css: {
        paddingBottom: '$teaserYInsetM',
      },
    },
    {
      theme: 'alfa',
      hasImage: true,
      css: {
        paddingLeft: '$teaserXInsetM',
        paddingRight: '$teaserXInsetM',
        '@bp532': {
          paddingLeft: '$teaserXInsetL',
          paddingRight: '$teaserXInsetL',
        },
      },
    },
    {
      theme: 'bravo',
      hasImage: true,
      css: {
        paddingLeft: '$teaserContentXInsetM',
        paddingRight: '$teaserContentXInsetM',

        '@bp532': {
          paddingLeft: '$teaserContentXInsetL',
          paddingRight: '$teaserContentXInsetL',
        },
      },
    },
    {
      theme: 'charlie',
      hasImage: true,
      css: {
        paddingLeft: '$teaserXInsetM',
        paddingRight: '$teaserXInsetM',
        '@bp532': {
          paddingLeft: '$teaserXInsetL',
          paddingRight: '$teaserXInsetL',
        },
      },
    },
    {
      theme: 'nettavisen',
      hasImage: true,
      css: {
        paddingLeft: '$teaserXInsetM',
        paddingRight: '$teaserXInsetM',
        '@bp532': {
          paddingLeft: '$teaserSkinInsetL',
          paddingRight: '$teaserSkinInsetL',
        },
      },
    },
  ],
  'brick-carousel &': {
    padding: '6px $x2s',
    '@bp532': {
      padding: '6px $x2',
    },
  },
});

export const premiumWrapper = css({
  gridArea: 'premium',
  alignSelf: 'end',
  display: 'flex',
  variants: {
    theme: {
      alfa: {
        '.skin & .premium-icon': {
          padding: '2px',
          '@bp532': { padding: '3px' },
        },
      },
      bravo: {},
      charlie: {},
      nettavisen: {
        '.skin & .premium-icon': {
          padding: '2px',
          '@bp532': { padding: '3px' },
        },
      },
      alt: {},
    },
    hasImage: {
      true: {},
      false: {},
    },
  },
  compoundVariants: [
    {
      theme: 'alfa',
      hasImage: true,
      css: {
        padding: '$teaserStackBM $teaserXInsetM $teaserYInsetM 0px',
        '@bp532': {
          padding: '$teaserStackBL $teaserXInsetL $teaserYInsetL 0px',
        },
      },
    },
    {
      theme: 'bravo',
      hasImage: true,
      css: {
        padding:
          '$teaserStackCM $teaserContentXInsetM $teaserContentYInsetM 0px',
        '@bp532': {
          padding:
            '$teaserStackCL $teaserContentXInsetL $teaserContentYInsetL 0px',
        },
      },
    },
    {
      theme: 'charlie',
      hasImage: true,
      css: {
        padding: '$teaserStackBM $teaserXInsetM $teaserYInsetM 0px',
        '@bp532': {
          padding: '$teaserStackBL $teaserXInsetL $teaserYInsetL 0px',
        },
      },
    },
    {
      theme: 'nettavisen',
      hasImage: true,
      css: {
        padding: '$teaserStackBM $teaserXInsetM $teaserYInsetM 0px',
        '@bp532': {
          padding: '$teaserStackBL $teaserSkinInsetL 0.4375rem 0px',
        },
      },
    },
  ],

  'brick-carousel &': {
    padding: '0 $x2s $x2s',
    '@bp532': {
      padding: '0 $x2 $x2',
    },
  },
});
export const imageWrapper = css({
  gridArea: 'image',
  position: 'relative',

  //TODO: feature border radius token
  '& .imagewrapper': {
    borderRadius: '$teaser !important',
  },
});

export const featureGradientStyle = css({
  position: 'absolute',
  bottom: '0px',
  left: '0px',
  right: '0px',
  height: '100%',
  pointerEvents: 'none',

  variants: {
    layer: {
      one: {
        minHeight: '90px',
        height: 'var(--brick-teaser-overlay-container-height)',
        background:
          'linear-gradient(10deg, currentColor, transparent, 73%, transparent )',
      },
      two: {
        minHeight: '80px',
        height: 'calc(var(--brick-teaser-overlay-container-height) * 1.6)',
        background:
          'linear-gradient(3deg, currentColor, transparent, 65%, transparent )',
      },
      three: {
        minHeight: '70px',
        height: 'var(--brick-teaser-overlay-container-height)',
        background:
          'linear-gradient(0deg, currentColor, transparent, 43%, transparent )',
      },
      four: {
        minHeight: '75px',
        height: 'calc(var(--brick-teaser-overlay-container-height) * 1.6)',
        background:
          'linear-gradient(-3deg, currentColor, transparent, 65%, transparent )',
      },
    },
  },
});
