import { SportsMeta, SportsTeam, Teaser } from '../../types.js';
import { baseBodyStyle } from '../../components/body/body-style.js';
import teaserImageTemplate from '../../components/image/image.js';
import titleTemplate from '../../components/title/title.js';
import teaserVideoTemplate from '../../components/image/video.js';
import countdownTemplate from '../../components/countdown/countdown.js';
import {
  imageSportLayoutStyle,
  noImageSportLayoutStyle,
  pillContainer,
  premiumWrapper,
  gradientStyle,
  titleWrapper,
  avatarOverlay,
  sportTeaserStyle,
  imageWrapper,
} from './sportStyle.js';
import { renderStrings } from '../../utils/renderStrings.js';
import {
  breakingPill,
  countdownPill,
  plusPill,
  vignettePill,
} from '../../components/pills/pills.js';
import { renderBrickPillbox } from '@amedia/brick-pill/template';
import getPremiumIcon from '../../components/common/premiumIcon.js';
import { renderBrickAvatar } from '@amedia/brick-avatar/template';

interface ComponentTemplate {
  data: Teaser;
  useAutolayout: boolean;
  aoiBeta: boolean;
}

function getTeaserDirection(version) {
  return version === 'imageLeft' ? 'row' : 'column';
}

function getImageMarkup(data, useAutolayout, aoiBeta) {
  const { body, theme } = data;

  const { image, version, teaserType, title, videoData, hasImage } = body;

  let markup = '';

  const imageMarkup = hasImage
    ? teaserImageTemplate({
        data: image,
        useAutolayout,
        aoiBeta,
        disableOverlay: true,
      })
    : '';

  const videoMarkup = videoData
    ? teaserVideoTemplate({
        videoData,
        theme,
        title: title.title || '',
        teaserType,
        version,
        overlay: image.overlay,
        disableOverlay: true,
      })
    : '';

  if (videoMarkup || imageMarkup) {
    markup = `<div class="${imageWrapper}">
      ${imageMarkup}
      ${videoMarkup}
      <div class="${gradientStyle}"></div>
      </div>`;
  }

  return markup;
}

function getTeamAvatar(team: SportsTeam) {
  const dataImg = team.logo;
  const dataLetters = team.name ? team.name[0] : '';

  const dataAlttext = dataImg
    ? `Logo for laget ${team.name}`
    : dataLetters
    ? `Første bokstaven fra laget ${team.name} i et skjold`
    : '';

  return renderBrickAvatar({
    dataAlttext,
    dataImg,
    dataLetters,
    dataLettersShape: 'shield',
    dataSize: 'small',
    dataShape: 'circle',
  });
}

function getSportsLogoOverlay(sportsMeta: SportsMeta) {
  const homeTeamAvatar = sportsMeta.homeTeam
    ? getTeamAvatar(sportsMeta.homeTeam)
    : '';
  const awayTeamAvatar = sportsMeta.awayTeam
    ? getTeamAvatar(sportsMeta.awayTeam)
    : '';

  if (!(homeTeamAvatar.length > 0) && !(awayTeamAvatar.length > 0)) {
    return '';
  }

  return `<div class="${avatarOverlay}">
    ${homeTeamAvatar}
    ${awayTeamAvatar}
  </div>`;
}

function getPills({
  hasImage,
  direction,
  premiumVersion,
  breakingNews,
  countdown,
  vignette,
  theme,
}) {
  const pillsParams = {
    skin: hasImage && direction === 'column' ? 'none' : 'sport',
    theme,
    filled: hasImage && direction === 'column',
  };

  const pluspill =
    theme === 'nettavisen' && !hasImage && premiumVersion
      ? plusPill({ ...pillsParams, version: premiumVersion })
      : '';
  const pills = renderStrings`${breakingPill({ ...pillsParams, breakingNews })}
     ${countdownPill({
       ...pillsParams,
       countdown: countdown?.position === 'vignette' ? countdown : undefined,
     })} ${vignettePill({
    ...pillsParams,
    text: vignette.vignette,
  })} ${pluspill}`;
  const pillbox = renderBrickPillbox({ filled: pillsParams.filled }, pills);
  return pillbox
    ? `<div class="sport-pills ${pillContainer({
        hasImage,
      })}">${pillbox}</div>`
    : '';
}

export const getSportTemplate: (
  componentTemplate: ComponentTemplate
) => string = ({ data, useAutolayout, aoiBeta }: ComponentTemplate) => {
  const { body, theme, countdown, breakingNews, sportsMeta, version } = data;

  const { url, mirror, premiumVersion, vignette, title, hasImage } = body;
  const mirrorClass = mirror ? 'mirror' : '';
  const direction = getTeaserDirection(version);
  const imageOrVideoMarkup = getImageMarkup(data, useAutolayout, aoiBeta);
  const pillsMarkup = getPills({
    theme,
    hasImage,
    direction,
    premiumVersion,
    breakingNews,
    countdown,
    vignette,
  });
  const pillsClass = pillsMarkup ? 'pills' : '';

  const premiumBadge =
    premiumVersion && !pillsMarkup.includes('plus')
      ? `<div class="premium-wrapper ${premiumWrapper({
          hasImage,
          theme,
        })}">${getPremiumIcon({
          theme,
          premiumVersion,
          filled: true,
        })}</div>`
      : '';
  const premiumClass = premiumBadge ? 'premium' : '';

  const layoutStyles = hasImage
    ? imageSportLayoutStyle({
        version,
      })
    : noImageSportLayoutStyle({
        theme,
        premium: !!premiumBadge,
        pills: !!pillsMarkup,
      });

  const sportsLogoOverlay =
    sportsMeta && hasImage && version !== 'imageLeft'
      ? getSportsLogoOverlay(sportsMeta)
      : '';

  return `<a href="${url}" itemprop="url" class="sport_body  ${mirrorClass} ${pillsClass} ${premiumClass} ${layoutStyles} ${baseBodyStyle} ${sportTeaserStyle}">
  ${imageOrVideoMarkup}
  ${sportsLogoOverlay}
  ${premiumBadge}
  ${pillsMarkup}
  <div class="sport_title ${titleWrapper({
    hasImage,
    pills: !!pillsMarkup,
  })}">
  ${titleTemplate(title, useAutolayout)}
  </div>
</a>
${countdownTemplate(data)}`;
};
