import { css } from '../../styles';

const containerPaddingM = {
  padding:
    '$teaserFooterTipusTopM $teaserFooterTipusRightM $teaserFooterTipusBottomM $teaserFooterTipusLeftM',
};

const containerPaddingL = {
  padding:
    '$teaserFooterTipusTopL $teaserFooterTipusRightL $teaserFooterTipusBottomL $teaserFooterTipusLeftL',
};

export const tipUsDivider = css({
  unset: 'all',
  padding: '0',
  margin: '0',
  borderStyle: 'none',
  alignSelf: 'flex-start',
  display: 'flex',
  flex: '1 1 100%',
  borderWidth: '1.3px',
  borderBottomStyle: 'solid',
  borderTop: 'none',
  borderLeft: 'none',
  borderRight: 'none',
  opacity: '0.65',
  variants: {
    theme: {
      alt: {},
      alfa: {},
      bravo: {
        margin: '0px $teaserFooterTipusTopM',
        '@bp532': {
          margin: '0px $teaserFooterTipusTopL',
        },
      },
      charlie: {},
      nettavisen: {},
    },
    skin: {
      sport: {},
      none: { borderColor: 'currentColor' },
      black: {},
      'custom-one': {},
      'custom-two': {},
      'custom-three': {},
      highlight: {},
      opinion: {},
    },
  },
  compoundVariants: [
    {
      theme: 'alfa',
      skin: 'none',
      css: {
        borderColor: 'currentColor',
      },
    },
    {
      theme: 'alfa',
      skin: 'black',
      css: {
        borderColor: '$supportiveBlackFg',
      },
    },
    {
      theme: 'alfa',
      skin: 'highlight',
      css: {
        borderColor: '$supportiveHighlightDivider',
        opacity: '100%',
      },
    },
    {
      theme: 'alfa',
      skin: 'custom-one',
      css: {
        borderColor: `var(--custom-background-color-one-front, $colors$supportiveCustomOneFg)`,
      },
    },
    {
      theme: 'alfa',
      skin: 'custom-two',
      css: {
        borderColor: 'currentColor',
      },
    },
    {
      theme: 'bravo',
      skin: 'none',
      css: {
        borderColor: 'transparent',
      },
    },
    {
      theme: 'bravo',
      skin: 'black',
      css: {
        borderColor: 'transparent',
      },
    },
    {
      theme: 'bravo',
      skin: 'highlight',
      css: {
        borderColor: '$supportiveHighlightFg',
        paddingTop: '$teaserFooterTipusTopM',
      },
    },
    {
      theme: 'bravo',
      skin: 'custom-one',
      css: {
        borderColor: `var(--custom-background-color-one-front, $colors$supportiveCustomOneFg)`,
        paddingTop: '$teaserFooterTipusTopM',
      },
    },
    {
      theme: 'bravo',
      skin: 'custom-two',
      css: {
        borderColor: `var(--custom-background-color-two-front, $supportiveCustomTwoFg)`,
        paddingTop: '$teaserFooterTipusTopM',
      },
    },
    {
      theme: 'bravo',
      skin: 'custom-three',
      css: {
        borderColor: `var(--custom-background-color-three-front, $supportiveCustomThreeFg)`,
        paddingTop: '$teaserFooterTipusTopM',
      },
    },
    {
      theme: 'charlie',
      skin: 'none',
      css: {
        borderColor: 'currentColor',
      },
    },
    {
      theme: 'charlie',
      skin: 'black',
      css: {
        borderColor: '$supportiveBlackFg',
      },
    },
    {
      theme: 'charlie',
      skin: 'highlight',
      css: {
        borderColor: '$supportiveHighlightDivider',
      },
    },
    {
      theme: 'charlie',
      skin: 'custom-one',
      css: {
        borderColor: 'currentcolor',
      },
    },
    {
      theme: 'charlie',
      skin: 'custom-two',
      css: {
        borderColor: 'currentcolor',
      },
    },
    {
      theme: 'nettavisen',
      skin: 'black',
      css: {
        borderColor: 'transparent',
      },
    },
    {
      theme: 'nettavisen',
      skin: 'highlight',
      css: {
        borderColor: '$supportiveHighlightDivider',
        opacity: '100%',
      },
    },
    {
      theme: 'nettavisen',
      skin: 'black',
      css: {
        borderColor: '$supportiveBlackFg',
      },
    },
    {
      theme: 'nettavisen',
      skin: 'custom-one',
      css: {
        borderColor: 'transparent',
      },
    },
    {
      theme: 'nettavisen',
      skin: 'custom-two',
      css: {
        borderColor: 'transparent',
      },
    },
  ],
  defaultVariants: {
    theme: 'alfa',
  },
});

export const tipUsContainer = css({
  display: 'flex',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  alignItems: 'center',

  '& brick-button-v7 > a': {
    color: '$buttonOutlinedFg !important',
    whiteSpace: 'nowrap',
    flex: '0 0 auto',
  },

  variants: {
    theme: {
      alt: {},
      alfa: {
        ...containerPaddingM,
        '@bp532': {
          ...containerPaddingL,
        },
        '@bp680': {
          ...containerPaddingL,
        },
      },
      bravo: {},
      charlie: {
        ...containerPaddingM,
        '@bp532': {
          ...containerPaddingL,
        },
        '@bp680': {
          ...containerPaddingL,
        },
      },
      nettavisen: {},
    },
    skin: {
      sport: {},
      none: {},
      black: {},
      'custom-one': {},
      'custom-two': {},
      'custom-three': {},
      highlight: {},
      opinion: {},
    },
    marker: {
      true: {},
      false: {},
    },
    hasImage: {
      true: {},
      false: {},
    },
  },

  compoundVariants: [
    {
      theme: 'nettavisen',
      skin: 'none',
      css: {
        ...containerPaddingM,
        '@bp532': {
          ...containerPaddingL,
        },
        '@bp680': {
          ...containerPaddingL,
        },
      },
    },
    {
      theme: 'nettavisen',
      skin: 'black',
      css: {
        padding: '$teaserFooterTipusTopM 0px 0px',
        '@bp532': {
          padding: '$teaserFooterTipusTopL 0px 0px',
        },
      },
    },
    {
      theme: 'nettavisen',
      skin: 'highlight',
      css: {
        padding: '$teaserFooterTipusTopM 0px 0px',
        '@bp532': {
          padding: '$teaserFooterTipusTopL 0px 0px',
        },
      },
    },
    {
      theme: 'nettavisen',
      skin: 'custom-three',
      css: {
        padding: '$teaserFooterTipusTopM 0px 0px',
        '@bp532': {
          padding: '$teaserFooterTipusTopL 0px 0px',
        },
      },
    },
    {
      theme: 'nettavisen',
      marker: true,
      hasImage: false,
      css: {
        '@bp532': {
          gap: 'calc(2 * $teaserStackBL)',
          display: 'flex',
          flexWrap: 'wrap',
        },
      },
    },
    {
      theme: 'bravo',
      skin: 'custom-one',
      css: {
        ...containerPaddingM,
        '@bp532': {
          ...containerPaddingL,
        },
        '@bp680': {
          ...containerPaddingL,
        },
      },
    },
    {
      theme: 'bravo',
      skin: 'custom-two',
      css: {
        ...containerPaddingM,
        '@bp532': {
          ...containerPaddingL,
        },
        '@bp680': {
          ...containerPaddingL,
        },
      },
    },
    {
      theme: 'bravo',
      skin: 'custom-three',
      css: {
        ...containerPaddingM,
        '@bp532': {
          ...containerPaddingL,
        },
        '@bp680': {
          ...containerPaddingL,
        },
      },
    },
  ],
});

export const tipUsTextStyle = css({
  fontstyle: 'tipusM',
  margin: 0,
  padding: 0,
  flex: '1 0 0%',
  minWidth: '55%',
});
