import { css } from '../../styles';

export const overlayBylineContainerStyle = css({
  gridArea: 'image',
  alignSelf: 'start',
  zIndex: '2',
  padding: '$teaserBoxInsetM',
  '@bp532': {
    padding: '$teaserBoxInsetL',
  },

  'brick-carousel &': {
    padding: '$x2s',
    '@bp532': {
      padding: '$x2',
    },
  },
});

export const bylineContainerStyle = css({
  $$imageSize: '',
  boxSizing: 'border-box',
  gridTemplateRows: 'minmax(min-content, 1fr)',
  borderRadius: '$pill',

  'brick-carousel [data-theme="alfa"] &, brick-carousel [data-theme="charlie"] &':
    {
      paddingTop: 'calc(var(--brick-sizes-avatarS) / 6)',
      paddingLeft: 'calc(var(--brick-sizes-avatarS) / 4)',
    },

  variants: {
    teaserImage: {
      true: {},
      false: {},
    },
    bylineImage: {
      true: {
        gridTemplateColumns: '$$imageSize minmax(min-content, 1fr)',
        gridTemplateAreas: '"profilepic text"',
        $$imageSize: '$sizes$avatarM',

        'brick-carousel &': {
          $$imageSize: '$sizes$avatarS',
        },
      },
      false: {
        gridTemplateColumns: '1fr',
        gridTemplateAreas: '"text"',
      },
    },
    theme: {
      alfa: {
        display: 'grid',
        alignItems: 'center',
        gap: '$x1',
      },
      bravo: {
        display: 'inline-grid',
        gap: '$x2s',
        height: '$$imageSize',
        ':not(.solo-group & @bp2)': {
          alignItems: 'center',
        },
        '& .body .brick-teaser-byline': {
          display: 'none',
        },
      },
      charlie: {
        display: 'grid',
        alignItems: 'center',
        gap: '$x1',
      },
      nettavisen: {
        display: 'inline-grid',
        alignItems: 'start',
        '& .body .brick-teaser-byline': {
          display: 'none',
        },
      },
      alt: {},
    },
  },
  compoundVariants: [
    {
      teaserImage: true,
      theme: 'bravo',
      css: {
        background: 'rgba(250,250,250,0.8)',
        color: 'black',
        '@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none)':
          {
            background: 'rgba(250,250,250,0.6)',
            '-webkit-backdrop-filter': 'blur(5px)',
            backdropFilter: 'blur(5px)',
          },
        '.solo-group &': {
          background: 'transparent',
          backdropFilter: 'unset',
          color: 'inherit',
        },
      },
    },
    {
      teaserImage: true,
      theme: 'nettavisen',
      css: {
        gap: 0,

        '.solo-group &': {
          gap: '$x1',
        },
      },
    },
    {
      teaserImage: false,
      theme: 'nettavisen',
      css: {
        gap: '$x1',
      },
    },
    {
      bylineImage: false,
      theme: 'bravo',
      css: {
        padding: '$x2s',
      },
    },
    {
      bylineImage: true,
      theme: 'bravo',
      css: {
        padding: '0 $x2s 0 0',
      },
    },
  ],
});

const textOverflowStyles = {
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
};

export const bylineStyle = css({
  $$left: '0cqi',
  $$right: '0cqi',
  $$padding: '0cqi',
  $$gaps: '0cqi',
  gridArea: 'text',
  display: 'flex',
  flexDirection: 'column',

  overflow: 'hidden',

  variants: {
    theme: {
      alfa: {},
      bravo: {
        justifyContent: 'center',
      },
      charlie: {},
      nettavisen: {},
      alt: {},
    },
    bylineImage: {
      true: {
        $$left: '$sizes$avatarM',
        $$gaps: '$space$x2',
        'brick-carousel &': {
          $$left: '$sizes$avatarS',
        },
      },
      false: {},
    },
    teaserImage: {
      true: {
        maxWidth: '220px',
        '@supports (container: inline-size)': {
          maxWidth: 'calc(100cqi - $$left - $$padding - $$right - $$gaps)',
        },
      },
      false: {
        container: 'byline-content / inline-size',
      },
    },
  },
  compoundVariants: [
    {
      teaserImage: false,
      theme: 'nettavisen',
      css: {
        '& p': {
          ...textOverflowStyles,
          maxWidth: '220px',
          '@supports (container: inline-size)': {
            maxWidth: '100cqi',
          },
        },
      },
    },
    {
      teaserImage: false,
      theme: 'bravo',
      css: {
        '& p': {
          ...textOverflowStyles,
          maxWidth: '220px',
          '@supports (container: inline-size)': {
            maxWidth: '100cqi',
          },
        },
      },
    },
    {
      teaserImage: true,
      theme: 'bravo',
      css: {
        $$padding: 'calc($space$teaserBoxInsetM * 2 + $space$x2 * 2)',
        '& p': {
          ...textOverflowStyles,
        },
      },
    },
  ],
});

export const authorTextStyle = css({
  fontstyle: 'avatarMetaL',
  margin: 0,

  'brick-carousel &': {
    fontstyle: 'avatarMetaM',
  },

  variants: {
    theme: {
      alfa: {},
      bravo: {},
      charlie: {},
      nettavisen: {
        lineHeight: '1.35',
      },
      alt: {},
    },
  },
});

export const roleTextStyle = css({
  fontstyle: 'baseMetaL',
  margin: 0,

  'brick-carousel &': {
    fontstyle: 'baseMetaM',
  },
});

export const bylineImage = css({
  gridArea: 'profilepic',
  $$imageSize: '$sizes$avatarM',
  borderRadius: '$avatar',
  backgroundColor: 'transparent',
  height: '$$imageSize',
  width: '$$imageSize',
  position: 'relative',
  overflow: 'hidden',
  minHeight: 'var(--focus-min-height)',
  maxHeight: 'var(--focus-max-height)',

  '& img': {
    objectFit: 'cover',
    objectPosition: 'left var(--focus-x) top var(--focus-y)',
    height: '100%',
    aspectRatio: 1,
  },

  'brick-carousel &': {
    $$imageSize: '$sizes$avatarS',
  },

  variants: {
    theme: { alfa: {}, bravo: {}, charlie: {}, nettavisen: {}, alt: {} },
    image: {
      true: {},
      false: {},
    },
  },

  compoundVariants: [
    {
      theme: 'bravo',
      image: true,
      css: {
        borderRadius: '$avatar 0 0 $avatar',
      },
    },
  ],
});

export const figureStyle = css({
  //reset default user agent style sheet on figure element
  marginBlockStart: 0,
  marginBlockEnd: 0,
  marginInlineStart: 0,
  marginInlineEnd: 0,
  margin: 0,
});

export const bubbleBylineStyle = css({
  container: 'byline-content / inline-size',
  gridArea: 'text',
  display: 'flex',
  flexDirection: 'column',

  overflow: 'hidden',

  '& p': {
    ...textOverflowStyles,
    maxWidth: '220px',
    '@supports (container: inline-size)': {
      maxWidth: '100cqi',

      //remove when refactor is done and parent element size is set by a grid space
      '[data-theme="alfa"].premium &, [data-theme="charlie"].premium &': {
        maxWidth: 'calc(100cqi - $sizes$premiumIconM)',
      },
    },
  },
});

export const markerContainerStyle = css({
  overflow: 'hidden',
});

export const markerStyle = css({
  color: 'white',
  background: 'black',
  '-webkit-box-decoration-break': 'clone',
  boxDecorationBreak: 'clone',

  '.solo-group &': {
    color: 'inherit',
    background: 'transparent',
  },

  variants: {
    textType: {
      author: {
        lineHeight: '1.4',
        padding: '2px 6px 2px',
        '.solo-group &': {
          lineHeight: 'inherit',
          padding: '0',
        },
      },
      role: {
        lineHeight: '1.25',
        padding: '1px 6px 2px',
        '.solo-group &': {
          lineHeight: 'inherit',
          padding: '0',
        },
      },
    },
  },
});
