import { css } from '../styles';

//the object teaserLayout are used to generate css for inline injection by scoponok
export const teaserLayout = {
  variants: {
    size: {
      980: {},
      500: {},
    },
    version: {
      imageLeft: {
        '&.has-image': {
          '--brick-teaser-display-top-pills': 'block',
          '--brick-teaser-display-overlay-pills': 'none',
        },
        '&[data-theme="nettavisen"].has-image': {
          '--brick-teaser-display-top-pills': 'flex',
          '--brick-teaser-display-overlay-pills': 'none',
        },
        '.solo-group &[data-theme="nettavisen"].has-image .body .brick-teaser-byline':
          {
            display: 'flex',
          },
        '&.has-image .body brick-pillbox': {
          display: 'flex',
        },
        '&.has-image brick-pillbox.overlay': {
          display: 'none',
        },
        '&[data-theme="nettavisen"]:not(.opinion) .marker .premium-overlay': {
          display: 'none',
        },
        '&[data-theme="nettavisen"].has-image .body .brick-teaser-byline': {
          display: 'flex',
        },
        '&[data-theme="nettavisen"].has-image .body .brick-teaser-byline div': {
          boxShadow: 'unset',
        },
        // DO not remove this! Scorponok will invalidate horizontal layout feature teasers because of this (and we want that)
        '&.feature .title_container': {
          display: 'none',
        },
        '&.opinion.themeNettavisen .title_container, &.opinion.themeBravo .title_container':
          {
            display: 'none',
          },
      },
      default: {
        '&.has-image': {
          '--brick-teaser-display-top-pills': 'none',
        },
      },
    },
  },
};

export const playVideoStyle = css({
  display: 'grid',
  height: '100%',
  alignItems: 'center',
  boxSizing: 'border-box',
  borderRadius: '$teaser',
  overflow: 'hidden',
  backgroundColor: 'transparent', //or baseInvertedBg?
  padding:
    'var(--brick-space-teaserPlayerInsetM, 0px)' /*$teaserVideoInsetMor $teaserVideoInsetM ?*/,
  '@bp500': {
    padding: 'var(--brick-space-teaserPlayerInsetM, 0px)',
  },
  'brick-carousel &': {
    padding: 0,
  },
  //Fixes the thin line below the video, so it fills the container
  '& .flowplayer': {
    display: 'block',
  },
});

export const teaserStyle = css({
  container: 'teaser / inline-size',
  display: 'grid',
  letterSpacing: '$mainTitle',
  height: '100%',
  clear: 'both',
  margin: 0,
  boxSizing: 'border-box',
  borderRadius: '$teaser',
  boxShadow: '$boxShadowTeaser',
  overflow: 'hidden',
  padding: 'var(--brick-teaser-padding-small-screen)',
  gap: '$teaserStackDM',

  // Hover effect, only on screens larger than mobile (480px)
  '&:hover': {
    '& span[itemprop="titleText"]': {
      textDecorationColor: 'currentColor',
    },
  },

  '&.has-image': {
    '--brick-teaser-display-top-pills': 'none',
  },

  '@bp532': {
    padding: 'var(--brick-teaser-padding-large-screen)',
    gap: '$teaserStackDL',
    '& .title span > span': {
      textDecoration: 'underline',
      textDecorationColor: 'transparent',
    },

    // Selectors for padding when in group with background, but no teaser skin
    '.optimus-complex-front[data-group="ghost"][class*="optimus-custom"] &:not(.skin), .optimus-complex-front[data-group="ghost"][class*="optimus-background"] &:not(.skin), .solo-group &:not(.opinion):not(.marker).skin':
      {
        padding: '$teaserSkinInsetM',
      },
  },

  // START: Selectors for group colors in DrEdition
  // BLACK
  '.optimus-background-black &': {
    '--b-teaser-color-bg': '$colors$supportiveBlackBg',
    '--b-teaser-color-fg': '$colors$supportiveBlackFg',
    '--b-opinion-bubble-color-bg': '#ffffff26',
  },

  // YELLOW AKA Highlight token
  '.optimus-background-yellow &': {
    '--b-teaser-color-bg': '$colors$supportiveHighlightBg',
    '--b-teaser-color-fg': '$colors$supportiveHighlightFg',
    '--b-opinion-bubble-color-bg': '#ffffff26',
  },

  // CUSTOM-ONE
  '.optimus-complex-front.optimus-custom-one &': {
    '--b-teaser-color-bg':
      'var(--custom-background-color-one, $colors$supportiveCustomOneBg)',
    '--b-teaser-color-fg':
      'var(--custom-background-color-one-front, $colors$supportiveCustomOneFg)',
    '--b-opinion-bubble-color-bg':
      'var(--custom-background-color-one-opaque-front)',
  },

  // CUSTOM-TWO
  '.optimus-complex-front.optimus-custom-two &': {
    '--b-teaser-color-bg':
      'var(--custom-background-color-two, $colors$supportiveCustomTwoBg)',
    '--b-teaser-color-fg':
      'var(--custom-background-color-two-front, $colors$supportiveCustomTwoFg)',
    '--b-opinion-bubble-color-bg':
      'var(--custom-background-color-two-opaque-front)',
  },

  // CUSTOM-THREE
  '.optimus-complex-front.optimus-custom-three &': {
    '--b-teaser-color-bg':
      'var(--custom-background-color-three, $colors$supportiveCustomThreeBg)',
    '--b-teaser-color-fg':
      'var(--custom-background-color-three-front, $colors$supportiveCustomThreeFg)',
    '--b-opinion-bubble-color-bg':
      'var(--custom-background-color-three-opaque-front)',
  },

  // OPINION
  '.optimus-background-opinion &': {
    '--b-teaser-color-bg':
      'var(--opinion-background-color, $colors$supportiveOpinionBg)',
    '--b-teaser-color-fg':
      'var(--opinion-color-front, $colors$supportiveOpinionFg)',
    '--b-opinion-bubble-color-bg': 'var(--opinion-color-opaque-front)',
  },

  // END: selectors for group colors in DrEdition
  '.optimus-complex-front &:not(.skin):not(.opinion) .body brick-pill[data-filled="false"] div':
    {
      color: 'inherit',
      backgroundColor: 'inherit',
    },

  '& a': {
    textDecoration: 'none',
    overflow: 'visible',
  },
  '& .teaser_body:focus-visible::before': {
    content: '""',
    position: 'absolute',
    inset: '0',
    outline: '3px solid $utilityInteractionFocusOutline',
    outlineOffset: '-3px',
    zIndex: '1',
  },
  'brick-carousel &': {
    borderRadius: '$baseM',
    '--brick-space-teaserYInsetL': '0',
    '--brick-space-teaserYInsetM': '0',
    '--brick-space-teaserXInsetM': '0',
    '--brick-space-teaserXInsetL': '0',
    '--brick-space-teaserSkinInsetM': '0',
    '--brick-space-teaserSkinInsetL': '0',
    '--brick-shadows-boxShadowTeaser': '0',
  },
  variants: {
    size: {
      980: {},
      500: {},
    },
    version: {
      imageLeft: {
        ...teaserLayout.variants.version.imageLeft,
      },
      default: {
        ...teaserLayout.variants.version.default,
      },
    },
    image: {
      true: {},
      false: {
        'brick-carousel &': {
          padding: 0,
        },
      },
    },
    theme: {
      charlie: {
        '& .body .top-pills': {
          gridColumn: '1 / 3',
        },
      },
    },
    marker: {
      true: {
        '.themeNettavisen&': {
          '&.custom-one, & .custom-one, &.custom-two, & .custom-two': {
            backgroundColor: 'transparent !important',
          },
          '& .title_container': {
            flex: '0',
            gap: '0',
          },
        },
      },
      false: {},
    },
  },
  defaultVariants: {
    version: '',
    image: false,
    theme: '',
    marker: false,
  },
});
