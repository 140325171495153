import { css } from '@amedia/brick-tokens';

import type { PillboxData } from './types.js';

export const pillBoxStyle: (variants: PillboxData) => string = css({
  display: 'flex',
  inlineSize: 'fit-content',
  maxWidth: '100%',
  overflow: 'hidden',
  borderRadius: '$pill',
  flexWrap: 'nowrap',
  $$dividerColorLocal: 'currentColor', //$colors$pillNoneDivider', //referencing color token by name
  flexShrink: '0',
  '& brick-pill:first-of-type': {
    fontWeight: '$pillBoldM',
    '@bp500': {
      fontWeight: '$pillBoldL',
    },
  },
  '& brick-pill:only-child:not([data-version="breaking"])': {
    fontWeight: '$pillM',
    '@bp500': {
      fontWeight: '$pillL',
    },
  },
  '&[data-filled="true"]': {
    $$dividerColorLocal: '$colors$pillNoneDivider', //referencing color token by name
  },

  '& brick-pill': {
    paddingInline: '$x2s',
    '& + brick-pill': {
      borderWidth: '1px',
      borderStyle: 'solid',
      borderImage:
        'linear-gradient(transparent calc(100% - $$dividerHeightLocal), $$dividerColorLocal calc(100% - $$dividerHeightLocal) $$dividerHeightLocal, transparent $$dividerHeightLocal) 1',
      borderTop: 0,
      borderRight: 0,
      borderBottom: 0,
    },
    '&[data-skin="black"] + brick-pill': {
      $$dividerColorLocal: '$colors$pillBlackDivider',
    },
    '&[data-skin="sport"] + brick-pill': {
      $$dividerColorLocal: '$colors$pillSportDivider',
    },
    '&[data-skin="commercial"] + brick-pill': {
      $$dividerColorLocal: '$colors$pillCommercialDivider',
    },
    '&[data-skin="shopping"] + brick-pill': {
      $$dividerColorLocal: '$colors$pillShoppingDivider',
    },
    '&[data-skin="betting"] + brick-pill': {
      $$dividerColorLocal: '$colors$pillBettingDivider',
    },
  },

  '& brick-pill[data-version="vignette"]:first-of-type': {
    overflow: 'visible',
  },
  '& brick-pill[data-version="vignette"] ~ [data-version="vignette"]': {
    overflow: 'hidden',
  },

  variants: {
    filled: {
      true: {
        '& brick-pill': {
          '& + brick-pill': {
            $$dividerHeightLocal: '75%',
          },
          '&[data-version="alt"] + brick-pill': {
            $$dividerColorLocal: 'transparent',
          },
          '&[data-version="untold"] + brick-pill': {
            $$dividerColorLocal: 'transparent',
          },
          '&[data-skin="black"] + brick-pill': {
            $$dividerColorLocal: '$colors$pillBlackFilledDivider',
          },
          '&[data-skin="custom-two"] + brick-pill': {
            $$dividerColorLocal:
              'var(--custom-background-color-two-front, $colors$pillCustomTwoFilledDivider)',
          },
          '&[data-skin="custom-three"] + brick-pill': {
            $$dividerColorLocal:
              'var(--custom-background-color-three-front, $colors$pillCustomThreeFilledDivider)',
          },
          '&[data-skin="highlight"] + brick-pill': {
            $$dividerColorLocal: '$colors$pillHighlightFilledDivider',
          },
          '&[data-skin="opinion"] + brick-pill': {
            $$dividerColorLocal:
              'var(--opinion-color-front, $color$pillOpinionFilledFg)',
          },
          '&[data-skin="black"][data-version="breaking"] + brick-pill': {
            $$dividerColorLocal: 'transparent',
          },
          '&[data-skin="custom-one"] + brick-pill': {
            $$dividerColorLocal:
              'var(--custom-background-color-one-front, $colors$pillCustomOneFilledDivider)',
          },
          '&[data-skin="commercial"] + brick-pill': {
            $$dividerColorLocal: '$colors$pillCommercialFilledDivider',
          },
          '&[data-skin="shopping"] + brick-pill': {
            $$dividerColorLocal: '$colors$pillShoppingFilledDivider',
          },
          '&[data-skin="sport"] + brick-pill': {
            $$dividerColorLocal: '$colors$pillSportFilledDivider',
          },
          '&[data-skin="betting"] + brick-pill': {
            $$dividerColorLocal: '$colors$pillBettingFilledDivider',
          },
          '&[data-skin][data-version="alt"] + brick-pill': {
            $$dividerColorLocal: 'transparent',
          },
          '&[data-skin][data-version="untold"] + brick-pill': {
            $$dividerColorLocal: 'transparent',
          },
        },
      },
      false: {
        $$dividerHeightLocal: '100%',
        overflow: 'visible', // to allow space for icon animation
        '& :first-child': {
          paddingInlineStart: 'unset',
        },
        '& brick-pill': {
          '&:not([data-version="breaking"]):last-child': {
            overflow: 'hidden',
          },
        },
      },
    },
  },
  defaultVariants: {
    filled: false,
  },
});
