import type { BrickIconTemplate } from './types';
import { containerStyle, iconStyle } from './icon-styles';

export { containerStyle as iconContainerStyle, iconStyle };

// Check if we want to show or hide the icon from screenreaders
const ariaSr = (iconText) => {
  if (!iconText) {
    return 'aria-hidden="true"';
  }
  return 'role="graphics-symbol"';
};

export function brickIconTemplate(data: BrickIconTemplate) {
  const { dataIconId, dataIconText } = data;

  const iconTxt = dataIconText
    ? `<title style="display: inline;">${dataIconText}</title>`
    : '';

  const asset = dataIconId ? `<use href="#${dataIconId}" />` : '';

  const markup = `
      <div class="svg-wrap ${iconStyle({ iconId: dataIconId })}">
        <svg ${ariaSr(dataIconText)} width="32" height="32">
          ${iconTxt}
          ${asset}
        </svg>
      </div>
      `;
  if (data.isRendered) {
    return `<brick-icon-v${data.version} is-rendered ${
      dataIconText ? 'data-icon-text="${dataIconText}"' : ''
    } data-icon-id="${dataIconId}">${markup}</brick-icon-v${data.version}>`;
  }
  return markup;
}

export function renderBrickIcon(data: BrickIconTemplate) {
  data.isRendered = true;
  data.version = '2';
  return brickIconTemplate(data);
}
