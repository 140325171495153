import { css } from '@amedia/brick-tokens';

export { css, getCssText, getThemeByDesign } from '@amedia/brick-tokens';

export const mediaBreakpoints = {
  bp1: '(min-width: 533px) and (max-width: 1000px)',
  bp2: '(max-width: 532px)',
};

export const brickTeaserElement = css({
  'brick-carousel &': {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    background: '$baseBg',
    boxSizing: 'border-box',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: '$utilitySecondaryBg',
    borderRadius: '$baseM',
    backgroundClip: 'padding-box',
  },
  '.themeNettavisen &': {
    display: 'block',
  },
  '.themeBravo &, .themeAlfa &, .themeCharlie &': {
    height: '100%',
  },
});
