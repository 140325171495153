import { renderBrickPillbox } from '@amedia/brick-pill/template';

import { vignettePill } from '../pills/pills';

import { bettingOverlayContainer } from './betting-style.js';

export const bettingOverlayTemplate = () => {
  return `<div class="${bettingOverlayContainer}">${renderBrickPillbox(
    { filled: true },
    `${vignettePill({
      text: '18+',
      filled: true,
    })}`
  )}${renderBrickPillbox(
    { filled: true },
    `${vignettePill({
      text: 'Hjelpelinjen.no',
      filled: true,
      skin: 'black',
    })}`
  )}</div>`;
};
