import { css } from '../../styles';
export const bettingOverlayContainer = css({
  position: 'absolute',
  display: 'inline-flex',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  top: 0,
  right: 0,
  padding: '$teaserBoxInsetM',
  opacity: '0.9',
  '@bp532': {
    padding: '$teaserBoxInsetL',
  },
});
