import type { Design } from '@amedia/brick-tokens';

import type {
  VideoData,
  ImageData,
  TeaserType,
  TeaserVersion,
  Overlay,
  Skin,
} from '../../types';
import overlayTemplate from '../overlay/overlay';
import { getImageTag } from '../../utils/getImageTag';
import { getRatio } from '../../utils/getRatio';
import { getVideoPreview } from '../../utils/getVideoPreview';

import { imageStyle } from './image-style.js';
import { skinColorStyle } from '../../teaserTypes/skinStyles';

interface VideoTemplateData {
  videoData: VideoData;
  theme: Design;
  title: string;
  teaserType: TeaserType;
  version?: TeaserVersion;
  overlay: Overlay;
  disableOverlay?: boolean;
  skin?: Skin;
}

export default function teaserVideoTemplate(data: VideoTemplateData) {
  if (!data.videoData.thumbnailUrl) {
    return '';
  }

  const {
    videoData,
    title,
    teaserType,
    version,
    overlay,
    theme,
    disableOverlay,
    skin,
  } = data;

  const { url, thumbnailUrl, height, width } = videoData;

  const videoRatio = getRatio(videoData);
  const elementPadding = videoRatio ? `padding-top:${videoRatio}%` : '';
  const imageData: ImageData = { url: thumbnailUrl, height, width };
  const imageTag = getImageTag({
    imageData,
    title,
    teaserType,
  });
  const markup = getVideoPreview({ url, title, imageElement: imageTag });
  const styles = imageStyle({
    theme,
    version,
    preview: !!url,
    teaserType,
    aoiBeta: false,
  });

  const overlayMarkup = !disableOverlay ? overlayTemplate(overlay) : '';

  return `
    <figure class="teaser_image ${styles} ${skinColorStyle({
    skin,
    image: true,
  })}" data-theme="${theme}">
      <div class="imagewrapper" style="${elementPadding}">
        <div>
          ${markup}
        </div>
        ${overlayMarkup}
      </div>
    </figure>
    `;
}
