import { css } from '../../styles';

export const breakingStyle = css({
  display: 'inline-block',
  position: 'relative',
  marginInlineEnd: '0',
  '& div': {
    aspectRatio: '1/1',
    position: 'absolute',
    width: 'auto',
    height: '70%',
    top: '17%',
  },
  '& svg': {
    width: 'auto',
    height: '100%',
  },

  // '&.breaking-news-text::before': {
  //   content: ' ',
  //   whiteSpace: 'pre',
  // },

  '&.breaking-news-text': {
    textTransform: 'uppercase',
  },
});

const breakingVariants = {
  variants: {
    skin: {
      none: {
        '& svg': {
          color: '$pillNonePulseBg',
        },
        '& brick-icon-v2 .svg-wrap': {
          '--brick--pulseColor70': '$colors$pillNonePulseStartBg',
          '--brick--pulseColor0': '$colors$pillNonePulseEndBg',
        },
        '.optimus-complex-front &': {
          '& svg': {
            color: 'currentColor',
          },
          '& brick-icon-v2 .svg-wrap': {
            '--brick--pulseColor70': 'currentColor',
            '--brick--pulseColor0': 'transparent',
          },
        },
        '.optimus-complex-front [data-theme="nettavisen"] &': {
          '& brick-icon-v2 .svg-wrap': {
            '--brick--pulseColor70': 'transparent',
            '--brick--pulseColor0': 'transparent',
          },
        },
      },
      black: {
        '& svg': {
          color: '$pillBlackPulseBg',
        },
        '& brick-icon-v2 .svg-wrap': {
          '--brick--pulseColor70': '$colors$pillBlackPulseStartBg',
          '--brick--pulseColor0': '$colors$pillBlackPulseEndBg',
        },
      },
      'custom-one': {
        '& svg': {
          color: '$pillCustomOnePulseBg',
        },
        '& brick-icon-v2 .svg-wrap': {
          '--brick--pulseColor70': '$colors$pillCustomOnePulseStartBg',
          '--brick--pulseColor0': '$colors$pillCustomOnePulseEndBg',
        },
      },
      'custom-two': {
        '& svg': {
          color: '$pillCustomTwoPulseBg',
        },
        '& brick-icon-v2 .svg-wrap': {
          '--brick--pulseColor70': '$colors$pillCustomTwoPulseStartBg',
          '--brick--pulseColor0': '$colors$pillCustomTwoPulseEndBg',
        },
      },
      highlight: {
        '& svg': {
          color: '$pillHighlightPulseBg',
        },
        '& brick-icon-v2 .svg-wrap': {
          '--brick--pulseColor70': '$colors$pillHighlightPulseStartBg',
          '--brick--pulseColor0': '$colors$pillHighlightPulseEndBg',
        },
      },
      sport: {
        '& svg': {
          color: '$pillSportPulseBg',
        },
        '& brick-icon-v2 .svg-wrap': {
          '--brick--pulseColor70': '$colors$pillSportPulseStartBg',
          '--brick--pulseColor0': '$colors$pillSportPulseEndBg',
        },
      },
    },
  },
  defaultVariants: {
    skin: 'none',
  },
};

export const breakingPulseStyle = css({
  display: 'inline-block',
  position: 'relative',
  marginInlineEnd: '0.7em',
  '& brick-icon-v2': {
    display: 'inline-flex',
    marginInlineStart: 'calc($x1 * 0.2)',
    marginInlineEnd: 'calc($x1 * 0.3)',
  },
  '& div': {
    aspectRatio: '1/1',
    position: 'absolute',
    width: 'auto',
    height: '70%',
    top: '15%',
  },
  '& svg': {
    width: 'auto',
    height: '100%',
  },

  '@bp500': {
    '& brick-icon-v2': {
      marginInlineStart: 'calc($x1 * 0.2)',
      marginInlineEnd: 'calc($x1 * 0.7)',
    },
  },
  ...breakingVariants,
});

export const breakingTextStyle = css({
  display: 'inline-block',
  position: 'relative',
  marginInlineEnd: '0',
  textTransform: 'uppercase',
  fontFamily: '$baseBoostM',
  letterSpacing: '$baseBoostM',
  fontWeight: '$baseBoostM',
  $$breakingBoostColor: '',
  color: '$$breakingBoostColor',
  variants: {
    skin: {
      none: {
        $$breakingBoostColor: '$colors$supportiveNoneBreakingFg',
      },
      black: {
        $$breakingBoostColor: '$colors$supportiveBlackBreakingFg',
      },
      'custom-one': {
        $$breakingBoostColor:
          'var(--custom-background-color-one-front, $colors$supportiveCustomOneFg)',
      },
      'custom-two': {
        $$breakingBoostColor:
          'var(--custom-background-color-two-front, $colors$supportiveCustomTwoFg)',
      },
      sport: {},
      highlight: {
        $$breakingBoostColor: '$colors$supportiveHighlightBreakingFg',
      },
    },
  },
  defaultVariants: {
    skin: 'none',
  },
});
export const breakingElement = css({
  display: 'inline',
  gap: '$x2s',
  alignItems: 'baseline',
  textDecoration: 'none',
});
