import { css } from '@amedia/brick-tokens';

//the object bodyLayout are used to generate css for inline injection by scoponok
export const opinionBodyLayout = {
  variants: {
    version: {
      imageLeft: {
        '[data-theme="charlie"] &': {
          gridTemplateColumns: 'calc(34% + 3%) 1fr',
          gridTemplateAreas: '"image title"',
          gridRowGap: '$teaserStackBM',
          columnGap: '$teaserStackAM',

          '&.pills': {
            gridTemplateAreas: '"image pills" "image title"',
          },
          '&.premium': {
            gridTemplateAreas: '"image title premium"',
          },
          '&.pills.premium': {
            gridTemplateAreas: '"image pills pills" "image title premium"',
          },

          '@bp532': {
            gridRowGap: '$teaserStackBL',
            columnGap: '$teaserStackAL',
            '& .opinion-pills, & .title_container': {
              //x- and y-axis switch places on horizontal teaser layout
              padding: '$teaserContentXInsetL $teaserContentYInsetL',
            },
          },
        },

        '[data-theme="alfa"] &.pills, [data-theme="charlie"] &.pills': {
          gridTemplateRows: 'auto',
        },

        '&.mirror, &.mirror.premium': {
          gridTemplateColumns: '1fr calc(34% + 3%)',
          gridTemplateAreas: '"title image"',
          columnGap: 'calc($space$teaserStackAM + $space$teaserBoxInsetM)',
          gridArea: '"title image" "title image"',
          gridRowGap: '$teaserStackBM',
        },
        '&.mirror.pills, &.mirror.pills.premium': {
          gridTemplateAreas: '"pills image" "title image"',
        },
        '&.mirror .premium_container': {
          gridArea: 'image',
          zIndex: '2',
          padding: '$teaserBoxInsetM',
          '@bp532': {
            padding: '$teaserBoxInsetL',
          },
        },
      },
    },
  },
};

export const imageOpinionLayoutStyle = css({
  display: 'grid',
  gridTemplateRows: 'auto',
  gridAutoRows: '1fr auto',
  rowGap: '$teaserStackAM',
  columnGap: '$teaserStackBM',
  gridTemplateColumns: '1fr',
  gridTemplateAreas: '"image" "title"',

  //to be sure that imageLeft and default stylees are working properly with scorponok we need to target the themes like this
  '[data-theme="alfa"], [data-theme="charlie"] &': {
    gridColumnGap: 0,
  },
  '[data-theme="alfa"] &.pills, [data-theme="charlie"] &.pills': {
    gridTemplateAreas: '"image" "pills" "title"',
    gridTemplateRows: 'auto auto 1fr',
  },
  '[data-theme="alfa"] &.premium, [data-theme="charlie"] &.premium': {
    gridTemplateAreas: '"image image" "title premium"',
  },
  '[data-theme="alfa"] &.pills.premium, [data-theme="charlie"] &.pills.premium':
    {
      gridTemplateAreas: '"image image" "pills pills" "title premium"',
    },
  '[data-theme="bravo"] &.premium': {
    gridTemplateAreas: '"image image" "title title" ". premium"',
  },

  '& .teaser_image': {
    gridArea: 'image',
  },
  '@bp532': {
    rowGap: '$teaserStackAL',
    columnGap: '$teaserStackBL',
  },

  'brick-carousel &': {
    gap: 0,
  },

  variants: {
    version: {
      imageLeft: {
        ...opinionBodyLayout.variants.version.imageLeft,
      },
      default: {},
    },
  },
  //solo-group styles below
  '[data-group="ghost"] &': {
    '@container (inline-size >= 920px)': {
      display: 'grid',
      gap: '$teaserStackBL',
    },
  },
  '[data-group="ghost"] .solo-group &': {
    gridTemplateColumns: '30% auto',
    gridAutoRows: 'auto',
    gridTemplateAreas: '"image title" "image premium"',
    gridFlow: 'row',
    position: 'relative',

    '&.pills': {
      gridTemplateAreas: '"image pills" "image title" "image premium"',
    },

    '&.byline': {
      gridTemplateAreas:
        '"image byline byline" "image title title" "image . premium"',
    },
    '& .byline_wrapper': {
      gridArea: 'byline',
    },

    '&.byline.pills': {
      gridTemplateAreas:
        '"image . pills" "image byline byline" "image title title" "image . premium"',
      '& .byline_wrapper': {
        padding: '0px',
      },
      '@bp532': {
        gridTemplateAreas:
          '"image byline pills" "image title title" "image . premium"',
        '& .byline_wrapper': {
          padding: '$teaserBoxInsetL 0 0 0',
        },
      },
    },

    '& .opinion-pills': {
      gridArea: 'pills',
    },
  },
  '[data-group="ghost"] .solo-group &.mirror': {
    gridTemplateColumns: '1fr 30%',
    gridAutoRows: 'auto',
    gridTemplateAreas: '"title image"',
    gridFlow: 'row',
    position: 'relative',

    '&.pills': {
      gridTemplateAreas: '"pills image" "title image"',
    },

    '&.byline': {
      gridTemplateAreas: '"byline image" "title image"',
    },

    '&.pills.byline': {
      gridTemplateAreas: '"pills image" "byline image" "title image"',

      '& .byline_wrapper': {
        padding: '0px',
      },

      '@bp532': {
        gridTemplateColumns: 'auto 1fr 30%',
        gridTemplateAreas: '"byline pills image" "title title image"',
      },
    },

    '& .premium_container': {
      gridArea: 'image',
      zIndex: '2',
      padding: '$teaserBoxInsetM',
      '@bp532': {
        padding: '$teaserBoxInsetL',
      },
    },
  },
});

export const imageWrapper = css({
  gridArea: 'image',
  position: 'relative',
});

export const noImageOpinionLayoutStyle = css({
  display: 'grid',
  gridTemplateRows: 'auto',
  rowGap: '$teaserStackBM',
  columnGap: '$teaserStackBM',
  gridTemplateColumns: '1fr',

  padding: '$teaserContentYInsetM $teaserContentXInsetM',
  '@bp532': {
    padding: '$teaserContentYInsetL $teaserContentXInsetL',
    rowGap: '$teaserStackBL',
    columnGap: '$teaserStackBL',
  },

  'brick-carousel &': {
    padding: '$x2s',
    '@bp532': {
      padding: '$x2',
    },
  },

  variants: {
    theme: {
      alfa: {},
      bravo: {},
      charlie: {},
      nettavisen: {},
      alt: {},
    },
    pills: {
      true: {},
      false: {},
    },
    premium: {
      true: {},
      false: {},
    },
    byline: {
      true: {},
      false: {},
    },
  },
  compoundVariants: [
    {
      pills: false,
      premium: false,
      css: {
        gridTemplateAreas: '"title"',
      },
    },
    {
      pills: true,
      premium: false,
      byline: true,
      css: {
        gridTemplateAreas: '"byline pills" "title title"',
      },
    },
    {
      pills: true,
      premium: false,
      byline: false,
      css: {
        gridTemplateAreas: '"pills pills" "title title"',
      },
    },
    {
      pills: false,
      premium: true,
      theme: 'alfa',
      css: {
        gridTemplateAreas: '"title premium"',
      },
    },
    {
      pills: true,
      premium: true,
      theme: 'alfa',
      css: {
        gridTemplateAreas: '"pills ." "title premium"',
      },
    },
    {
      pills: false,
      premium: true,
      theme: 'bravo',
      css: {
        gridTemplateAreas: '"title title" ". premium"',
        '[data-group="ghost"] .solo-group &': {
          gridTemplateAreas: '"title premium"',
        },
      },
    },
    {
      pills: true,
      premium: true,
      theme: 'bravo',
      css: {
        gridTemplateAreas: '"byline pills" "title title" ". premium"',
      },
    },
    {
      pills: false,
      premium: true,
      theme: 'charlie',
      css: {
        gridTemplateAreas: '"title premium"',
      },
    },
    {
      pills: true,
      premium: true,
      theme: 'charlie',
      css: {
        gridTemplateAreas: '"pills ." "title premium"',
      },
    },
    {
      pills: false,
      premium: true,
      theme: 'nettavisen',
      css: {
        gridTemplateAreas: '"title title premium"',
      },
    },
    {
      pills: true,
      premium: true,
      theme: 'nettavisen',
      css: {
        gridTemplateAreas: '"byline pills" "title title"',
      },
    },
  ],
});

export const opinionStyle = css({
  container: 'teaser-content / inline-size',

  variants: {
    marker: {
      true: {
        gap: 0,
        '@bp532': {
          gap: 0,
        },
      },
    },
  },
  defaultVariants: {
    marker: false,
  },
});

export const premiumWrapper = css({
  display: 'flex',
  placeSelf: 'end',
  boxSizing: 'border-box',

  'brick-carousel &': {
    padding: '$x2s',
    '@bp532': {
      padding: '$x2',
    },
  },

  variants: {
    theme: {
      alfa: { gridArea: 'premium' },
      bravo: {
        gridArea: 'premium',
        paddingTop:
          'calc(var(--brick-space-teaserStackCM) - var(--brick-space-teaserStackBM))',

        '@bp532': {
          paddingTop:
            'calc(var(--brick-space-teaserStackCL) - var(--brick-space-teaserStackBL))',
        },
        '[data-group="ghost"] .solo-group &': {
          paddingTop: 0,
        },
      },
      charlie: { gridArea: 'premium' },
      nettavisen: {},
      alt: {},
    },
    hasByline: {
      true: {},
      false: { placeSelf: 'end' },
    },
    hasImage: {
      true: {},
      false: { gridArea: 'premium' },
    },
  },
  defaultVariants: {
    hasByline: false,
  },
  compoundVariants: [
    {
      theme: 'nettavisen',
      hasImage: true,
      css: {
        gridArea: 'image',
        zIndex: '2',
        padding: '$teaserBoxInsetM',

        '@bp532': {
          padding: '$teaserBoxInsetL',
        },
      },
    },
    {
      theme: 'bravo',
      hasImage: true,
      css: {
        paddingRight: '$teaserContentXInsetM',
        paddingBottom: '$teaserContentYInsetM',

        '@bp532': {
          paddingRight: '$teaserContentXInsetL',
          paddingBottom: '$teaserContentYInsetL',
        },
      },
    },
  ],
});

export const pillContainer = css({
  variants: {
    hasImage: {
      true: {},
      false: {
        gridArea: 'pills',
      },
    },
    marker: {
      true: {},
      false: {},
    },
    theme: {
      alfa: {},
      bravo: {},
      charlie: {},
      nettavisen: {},
      alt: {},
    },
  },
  deafaultVariants: {
    marker: false,
  },
  compoundVariants: [
    {
      hasImage: true,
      theme: 'alfa',
      css: {
        gridArea: 'pills',
        alignSelf: 'start',

        'brick-carousel &': {
          paddingLeft: '$x2s',
          paddingTop: '$x2s',
          '@bp532': {
            paddingLeft: '$x2',
            paddingTop: '$x2',
          },
        },
      },
    },
    {
      hasImage: true,
      theme: 'bravo',
      css: {
        gridArea: 'image',
        zIndex: 2,
        paddingLeft: '$teaserBoxInsetM',
        paddingBottom: '$teaserBoxInsetM',
        display: 'flex',
        placeSelf: 'end',
        justifySelf: 'start',

        'brick-carousel &': {
          paddingLeft: '$x2s',
          paddingBottom: '$x2s',
          '@bp532': {
            paddingLeft: '$x2',
            paddingBottom: '$x2',
          },
        },

        '[data-group="ghost"] .solo-group &': {
          placeSelf: 'end',
          alignSelf: 'start',
          padding: '$teaserContentYInsetM $teaserContentXInsetM 0 0',
          '@bp532': {
            padding: '$teaserContentYInsetL $teaserContentXInsetL 0 0',
          },
        },
      },
    },
    {
      hasImage: true,
      theme: 'charlie',
      css: {
        gridArea: 'pills',
        alignSelf: 'start',

        'brick-carousel &': {
          paddingLeft: '$x2s',
          paddingTop: '$x2s',
          '@bp532': {
            paddingLeft: '$x2',
            paddingTop: '$x2',
          },
        },
      },
    },
    {
      hasImage: true,
      theme: 'nettavisen',
      marker: true,
      css: {
        gridArea: 'image',
        zIndex: 2,
        display: 'flex',
        alignSelf: 'end',
        justifySelf: 'start',
      },
    },
    {
      hasImage: true,
      theme: 'nettavisen',
      marker: false,
      css: {
        gridArea: 'image',
        zIndex: 2,
        display: 'flex',
        alignSelf: 'end',
        justifySelf: 'start',
        paddingLeft: '$teaserBoxInsetM',
        paddingBottom: '$teaserBoxInsetM',

        '[data-group="ghost"] .solo-group &': {
          placeSelf: 'end',
          alignSelf: 'start',
          padding: '0',
          '@bp532': {
            padding: '0',
          },
        },
      },
    },
  ],
});

export const titleContainerStyle = css({
  gridArea: 'title',
  display: 'flex',
  flexDirection: 'column',
  gap: '$teaserStackTitleM',
  flex: '1',

  //@media over 532
  '@bp532': {
    gap: '$teaserStackTitleL',
  },
  // @media (min-width: 533px) and (max-width: 1000px)
  '@bp1': {
    gap: '$teaserStackTitleL',
  },

  variants: {
    theme: {
      nettavisen: {},
      bravo: {
        padding:
          'calc($space$teaserContentYInsetM * 2) calc($space$teaserContentXInsetM * 2)',
        '@bp532': {
          padding:
            'calc($space$teaserContentYInsetL * 2) calc($space$teaserContentXInsetL * 2)',
        },

        '[data-group="ghost"] .solo-group &': {
          padding: '$space$teaserContentYInsetM $space$teaserContentXInsetM',
          '@bp532': {
            padding: '$space$teaserContentYInsetL $space$teaserContentXInsetL',
          },
        },
      },
      alfa: {},
      charlie: {},
      alt: {},
    },
    hasImage: {
      true: {
        'brick-carousel &': {
          padding: '$x2s',
          '@bp532': {
            padding: '$x2',
          },
        },
      },
      false: {},
    },
    marker: {
      true: {
        gap: 0,
        rowGap: 0,
        '@bp532': {
          gap: 0,
          rowGap: 0,
        },
        '@bp1': {
          gap: 0,
          rowGap: 0,
        },
        '& span[itemprop="marker"]': {
          display: 'inline',
          padding: '0px 6px 2px',
          '-webkit-box-decoration-break': 'clone',
          boxDecorationBreak: 'clone',
          paddingTop: '0.08em',
          paddingBottom: '0.1em',
          lineHeight: '130%',
        },
      },
    },
  },
  defaultVariants: {
    theme: '',
    marker: false,
  },
});

export const gradientStyle = css({
  background:
    'linear-gradient(165deg, rgba(0, 0, 0, 0.7) 3%, rgba(0, 0, 0, 0.6) 6%, rgba(0, 0, 0, 0.5) 8%, rgba(0, 0, 0, 0.4) 11%, rgba(0, 0, 0, 0.3) 14%, rgba(0, 0, 0, 0.2) 20%, rgba(0, 0, 0, 0.1) 24%, transparent 32%)',
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  padding: '$teaserBoxInsetM',

  '@bp532': {
    padding: '$teaserBoxInsetL',
  },
});
