import getPremiumIcon from '../../components/common/premiumIcon';
import { Teaser } from '../../types';
import teaserImageTemplate from '../../components/image/image';
import teaserVideoTemplate from '../../components/image/video';
import { getRatio } from '../../utils/getRatio';
import {
  imageFeatureLayoutStyle,
  noImageFeatureLayoutStyle,
  featureStyle,
  pillContainer,
  premiumWrapper,
  imageWrapper,
  titleContainer,
  featureGradientStyle,
} from './featureStyle';
import { renderStrings } from '../../utils/renderStrings';
import { plusPill, vignettePill } from '../../components/pills/pills';
import { renderBrickPillbox } from '@amedia/brick-pill/template';
import getTemplateStructure from '../../components/title/templates/structure';

interface ComponentTemplate {
  data: Teaser;
  useAutolayout: boolean;
  aoiBeta: boolean;
}

export const getFeatureTemplate: (
  componentTemplate: ComponentTemplate
) => string = ({ data, useAutolayout, aoiBeta }: ComponentTemplate) => {
  const { body, theme, skin } = data;

  const {
    url,
    image,
    version,
    premiumVersion,
    teaserType,
    title,
    videoData,
    hasImage,
    vignette,
  } = body;
  let imageRatio;
  let featureOverlayHeight;
  let imageOrVideoMarkup = '';

  if (hasImage || videoData) {
    imageRatio = getRatio(image.imageData || videoData);
    featureOverlayHeight = imageRatio !== null ? imageRatio * 0.5 : 0;

    const imageMarkup = hasImage
      ? teaserImageTemplate({
          data: image,
          useAutolayout,
          aoiBeta,
          disableOverlay: true,
          skin,
        })
      : '';

    const videoMarkup = videoData
      ? teaserVideoTemplate({
          videoData,
          theme,
          title: title.title || '',
          teaserType,
          version,
          overlay: image.overlay,
          disableOverlay: true,
          skin,
        })
      : '';

    const featureOverlay =
      imageMarkup || videoMarkup
        ? `<div class="feature_gradient_overlay ${featureGradientStyle}" style="--brick-teaser-overlay-container-height:${featureOverlayHeight}%">
            <div class="${featureGradientStyle({ layer: 'one' })}"></div>
            <div class="${featureGradientStyle({ layer: 'two' })}"></div>
            <div class="${featureGradientStyle({ layer: 'three' })}"></div>
            <div class="${featureGradientStyle({ layer: 'four' })}"></div>
          </div>`
        : '';
    imageOrVideoMarkup = `<div class="${imageWrapper}">
    ${imageMarkup}
    ${videoMarkup}
    ${featureOverlay}
  </div>`;
  }

  const filled =
    skin === 'none' && (theme === 'alfa' || theme === 'nettavisen');

  const pillsParams = {
    skin,
    theme,
    filled: false,
  };

  const pluspill =
    theme === 'nettavisen' && !hasImage
      ? plusPill({ ...pillsParams, version: 'plus' })
      : '';
  const pills = renderStrings`${vignettePill({
    ...pillsParams,
    text: vignette.vignette,
  })}  ${pluspill}`;
  const pillbox = renderBrickPillbox({ filled: pillsParams.filled }, pills);
  const pillsMarkup = pillbox
    ? `<div class="feature-pills ${pillContainer({
        hasImage,
        theme,
      })}">${pillbox}</div>`
    : '';

  const premiumBadge =
    premiumVersion && !pillsMarkup.includes('plus')
      ? `<div class="premium-icon ${premiumWrapper({
          hasImage,
          theme,
        })}">${getPremiumIcon({
          theme,
          premiumVersion,
          filled,
        })}</div>`
      : '';

  const layoutStyles = imageOrVideoMarkup
    ? imageFeatureLayoutStyle({
        theme,
        premium: !!premiumVersion,
        pills: !!pillsMarkup,
      })
    : noImageFeatureLayoutStyle({
        theme,
        premium: !!premiumVersion,
        pills: !!pillsMarkup,
      });

  const titleContainerStyle = titleContainer({
    hasImage,
    theme,
    premium: !!premiumVersion,
  });
  const titleMarkup = getTemplateStructure(title, useAutolayout);

  return `<a href="${url}" itemprop="url" class="teaser_body ${layoutStyles} ${featureStyle}">
  ${imageOrVideoMarkup}
  ${pillsMarkup}
  <div class="title_container ${titleContainerStyle}">
   ${titleMarkup}
  </div>
  ${premiumBadge}
</a>`;
};
