import { renderBrickPillbox } from '@amedia/brick-pill/template';

import { renderStrings } from '../../utils/renderStrings';
import type { BodyPills, PremiumVersion } from '../../types';
import { mapToPillSkin } from '../../utils/getSkin';

import {
  vignettePill,
  breakingPill,
  countdownPill,
  plusPill,
  videoPill,
  galleryPill,
  ghostPill,
} from './pills';

export function noImageTopPillsStack(
  data: BodyPills,
  premiumVersion?: PremiumVersion
) {
  const {
    vignette,
    breakingNews,
    teaserType,
    theme,
    countdown,
    marker,
    videoMeta,
    skin,
    commercialCategory,
    ghost,
    text,
  } = data;

  let pillSkin = mapToPillSkin({ skin, commercialCategory });

  const isOpinion = teaserType === 'opinion';

  if (isOpinion && !marker) {
    pillSkin = 'opinion';
  }

  const isPremium = Boolean(premiumVersion);
  const filled =
    marker ||
    !!commercialCategory ||
    (isOpinion && theme === 'bravo') ||
    (isOpinion && theme === 'nettavisen');

  const opinionLabel = isOpinion
    ? vignette?.opinionType || vignette?.vignette
    : undefined;
  const vignetteText =
    vignette?.vignette !== opinionLabel ? vignette?.vignette : undefined;

  const isBreaking =
    (breakingNews?.breakingNews &&
      breakingNews.breakingNewsPosition === 'vignette') ||
    false;
  const isCountdownPill = !!countdown?.position && !!countdown?.date;
  const videoPillText = videoMeta?.videoDuration ? videoMeta.videoDuration : '';

  if (teaserType === 'alt' || teaserType === 'untold') {
    return ghostPill({
      version: teaserType,
      theme,
      filled: true,
      text: ghost?.publicationName,
    });
  }

  const bodyPills = {
    opinion: '',
    ...(opinionLabel && {
      opinion: vignettePill({
        text: opinionLabel,
        theme,
        skin: pillSkin,
        filled: true,
      }),
    }),
  };

  const bodyPillBox = {
    breaking: '',
    opinion: '',
    vignette: '',
    countdown: '',
    video: '',
    videoVignette: '',
    gallery: '',
    premium: '',
    ...(isBreaking && {
      breaking: breakingPill({
        breakingNews,
        skin: pillSkin,
        theme,
        filled,
      }),
    }),
    ...(opinionLabel && {
      opinion: vignettePill({
        text: opinionLabel,
        theme,
        skin: pillSkin,
        filled,
      }),
    }),
    ...(vignetteText && {
      vignette: vignettePill({
        version: 'vignette',
        text: vignetteText,
        skin: pillSkin,
        filled,
      }),
    }),
    ...(isCountdownPill && {
      countdown: countdownPill({
        version: 'countdown',
        countdown,
        skin: pillSkin,
        theme,
        filled,
      }),
    }),
    ...(teaserType === 'video' && {
      video: videoPill({
        version: teaserType,
        skin: pillSkin,
        theme,
        filled,
        text,
      }),
    }),
    ...(teaserType === 'video' &&
      videoPillText && {
        videoVignette: vignettePill({
          version: 'vignette',
          text: videoPillText,
          skin: pillSkin,
          filled,
        }),
      }),
    ...(teaserType === 'gallery' && {
      gallery: galleryPill({
        version: teaserType,
        text,
        skin: pillSkin,
        theme,
        filled,
      }),
    }),
    ...(isPremium && {
      premium: plusPill({
        version: premiumVersion,
        skin: pillSkin,
        filled,
        theme,
      }),
    }),
  };

  const pillStructureTemplate = {
    alfa: renderStrings`${bodyPillBox.breaking} ${bodyPillBox.opinion} ${bodyPillBox.countdown} ${bodyPillBox.vignette}`,
    charlie: renderStrings`${bodyPillBox.breaking} ${bodyPillBox.opinion} ${bodyPillBox.countdown} ${bodyPillBox.vignette}`,
    default: renderStrings`${bodyPillBox.breaking} ${bodyPills.opinion} ${bodyPillBox.countdown} ${bodyPillBox.vignette} ${bodyPillBox.premium}`,
    nettavisen: renderStrings`${bodyPillBox.breaking} ${bodyPillBox.opinion} ${bodyPillBox.countdown} ${bodyPillBox.vignette} ${bodyPillBox.video} ${bodyPillBox.videoVignette} ${bodyPillBox.gallery} ${bodyPillBox.premium}`,
  };

  const pillBoxChildren =
    pillStructureTemplate[theme] || pillStructureTemplate.default;

  const renderPills = pillBoxChildren || bodyPills.opinion;

  const markupTemplate = {
    default: renderBrickPillbox({ filled }, pillBoxChildren),
  };

  return renderPills ? markupTemplate[theme] || markupTemplate.default : '';
}
