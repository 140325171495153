import { css } from '@amedia/brick-tokens';

import type { PillStyleVariants } from './types';

export const pillTextStyle = css({
  all: 'unset',
  padding: '0',
  display: 'inline-flex',
  alignItems: 'center',
  variants: {
    icon: {
      true: {
        marginInlineStart: '5px',
      },
      false: {
        margin: '0',
      },
    },
  },
});

export const pillStyle = css({
  '&, & *': {
    boxSizing: 'border-box',
    padding: 0,
    display: 'inline-flex',
    alignItems: 'center',
  },

  display: 'inline-flex',
  alignItems: 'center',
  inlineSize: 'fit-content',
  fontstyle: 'pillM',
  overflow: 'hidden',
  maxWidth: '100%',
  flexShrink: 0,
  '& span': {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    display: 'block',
  },

  '@media (min-width: 500px)': {
    fontstyle: 'pillL',
  },

  '& brick-icon-v2': {
    display: 'inline-flex',
    alignItems: 'center',
  },

  '& svg': {
    color: 'currentColor',
    fill: 'currentColor',
    width: '11px',
    height: '11px',
  },
  '& > p': {
    margin: '0',
    padding: '0',
  },

  'brick-icon-v2': {
    position: 'relative',
  },

  variants: {
    version: {
      opinion: {},
      plus: {
        '& brick-icon-v2': {
          marginInlineEnd: '0px',
          display: 'inline-flex',
          alignItems: 'center',
        },
      },
      breaking: {},
      vignette: {
        flexShrink: '1',
      },
      countdown: {
        '&:last-child': {
          maxWidth: '100%',
          flexShrink: '1',
        },
        '& time': {
          whiteSpace: 'nowrap',
          fontVariantNumeric: 'tabular-nums',
          display: 'inline',
        },
      },
      alt: {
        '& brick-icon-v2': {
          marginInlineEnd: 'unset',
        },
        '& svg': {
          width: '42px',
          height: '14px',
        },
      },
      plusall: {
        '& svg': {
          width: '28px',
          height: '26px',
        },
      },
      gallery: {},
      video: {},
      podcast: {},
      untold: {},
    },

    filled: {
      true: {
        borderRadius: '$pill',
        padding: 'calc($x2s - 0.0625rem) $x2s $x2s $x2s ',
        'brick-pillbox &': {
          borderRadius: 'unset',
        },
      },
      false: {},
    },
  },

  defaultVariants: {
    version: 'vignette',
    filled: false,
  },

  compoundVariants: [
    {
      version: 'plusall',
      filled: true,
      css: {
        padding: 'calc($x2s / 3)',
        paddingRight: 'calc($x2s - 0.14rem)',
      },
    },
  ],
});

export const versionSpecificColors = css({
  variants: {
    version: {
      alt: {
        backgroundColor: '$pillAltFilledBg',
        $$colorLocal: '$colors$pillAltFilledFg',
        color: '$$colorLocal',
        '& svg': {
          color: '$$colorLocal',
        },
      },
      untold: {
        backgroundColor: '$supportiveUntoldBg',
        $$colorLocal: '$supportiveUntoldFg',
        color: '$$colorLocal',
      },
    },
  },
});

export const pillSkinStyle = css({
  color: 'inherit',
});

export const pillSkinFilledStyle = css({
  variants: {
    skin: {
      none: {
        backgroundColor: '$pillNoneFilledBg',
        color: '$pillNoneFilledFg',
      },
      black: {
        backgroundColor: '$pillBlackFilledBg',
        color: '$pillBlackFilledFg',
      },
      'custom-one': {
        backgroundColor:
          'var(--custom-background-color-one, $colors$pillCustomOneFilledBg)',
        color:
          'var(--custom-background-color-one-front, $color$pillCustomOneFilledFg)',
        '& [iconid="pill-breaking"] svg': {
          color: '$pillCustomOneFilledPulseBg',
        },
      },
      'custom-two': {
        backgroundColor:
          'var(--custom-background-color-two, $colors$pillCustomTwoFilledBg)',
        color:
          'var(--custom-background-color-two-front, $color$pillCustomTwoFilledFg)',
        '& [iconid="pill-breaking"] svg': {
          color: '$pillCustomTwoFilledPulseBg',
        },
      },
      'custom-three': {
        backgroundColor:
          'var(--custom-background-color-three, $colors$pillCustomThreeFilledBg)',
        color:
          'var(--custom-background-color-three-front, $colors$pillCustomThreeFilledFg)',
      },
      highlight: {
        backgroundColor: '$pillHighlightFilledBg',
        color: '$pillHighlightFilledFg',
      },
      opinion: {
        backgroundColor:
          'var(--opinion-background-color, $colors$pillOpinionFilledBg)',
        color: 'var(--opinion-color-front, $color$pillOpinionFilledFg)',
      },
      sport: {
        backgroundColor: '$pillSportFilledBg',
        color: '$pillSportFilledFg',
        '& [iconid="pill-breaking"] svg': {
          color: '$pillSportFilledPulseBg',
        },
      },
      commercial: {
        backgroundColor: '$pillCommercialFilledBg',
        color: '$pillCommercialFilledFg',
      },
      shopping: {
        backgroundColor: '$pillShoppingFilledBg',
        color: '$pillShoppingFilledFg',
      },
      betting: {
        backgroundColor: '$pillBettingFilledBg',
        color: '$pillBettingFilledFg',
        '& [iconid="pill-breaking"] svg': {
          color: '$pillBettingFilledPulseBg',
        },
      },
    },
  },
});

export const pillBreakingStyle = css({
  overflow: 'visible', // to allow space for the animation
  fontWeight: '$pillBoldM',

  '@bp500': {
    fontWeight: '$pillBoldL',
  },

  '& span[title]': {
    whiteSpace: 'initial',
    overflow: 'visible', // to allow space for the animation
  },

  // a11y
  '@media (prefers-reduced-motion)': {
    '& svg': {
      animation: 'none',
    },
  },

  defaultVariants: {
    filled: false,
    skin: 'none',
  },

  compoundVariants: [
    {
      skin: 'none',
      filled: false,
      css: {
        backgroundColor: '$pillNoneBreakingBg',
        color: '$pillNoneBreakingFg',
        '& brick-icon-v2 .svg-wrap': {
          '--brick--pulseColor70': '$colors$pillNonePulseStartBg',
          '--brick--pulseColor0': '$colors$pillNonePulseEndBg',
        },
        '& [iconid="pill-breaking"] svg': {
          color: '$pillNonePulseBg',
        },
      },
    },
    {
      skin: 'none',
      filled: true,
      css: {
        backgroundColor: '$pillNoneFilledBreakingBg',
        color: '$pillNoneFilledBreakingFg',
        '& brick-icon-v2 .svg-wrap': {
          '--brick--pulseColor70': '$colors$pillNoneFilledPulseStartBg',
          '--brick--pulseColor0': '$colors$pillNoneFilledPulseEndBg',
        },
        '& [iconid="pill-breaking"] svg': {
          color: '$pillNoneFilledPulseBg',
        },
      },
    },
    {
      skin: 'black',
      filled: false,
      css: {
        color: '$pillBlackBreakingFg',
        '& svg': {
          color: '$pillBlackPulseBg',
        },
        '& brick-icon-v2 .svg-wrap': {
          '--brick--pulseColor70': '$colors$pillBlackPulseStartBg',
          '--brick--pulseColor0': '$colors$pillBlackPulseEndBg',
        },
      },
    },
    {
      skin: 'black',
      filled: true,
      css: {
        backgroundColor: '$pillBlackFilledBreakingBg',
        $$colorLocal: '$colors$pillBlackFilledBreakingFg',
        color: '$$colorLocal',
        '& svg': {
          color: '$pillBlackFilledPulseBg',
        },
        '& brick-icon-v2 .svg-wrap': {
          '--brick--pulseColor70': '$colors$pillBlackFilledPulseStartBg',
          '--brick--pulseColor0': '$colors$pillBlackFilledPulseEndBg',
        },
      },
    },
    {
      filled: false,
      skin: 'custom-one',
      css: {
        color:
          'var(--custom-background-color-one-front, $colors$pillCustomOneBreakingFg)',
        '& svg': {
          color: '$pillCustomOnePulseBg',
        },
        '& brick-icon-v2 .svg-wrap': {
          '--brick--pulseColor70': '$colors$pillCustomOnePulseStartBg',
          '--brick--pulseColor0': '$colors$pillCustomOnePulseEndBg',
        },
      },
    },
    {
      filled: true,
      skin: 'custom-one',
      css: {
        backgroundColor:
          'var(--custom-background-color-one, $colors$pillCustomOneFilledBreakingBg)',
        color:
          'var(--custom-background-color-one-front, $colors$pillCustomOneFilledBreakingFg)',
        '& svg': {
          color: '$pillCustomOneFilledPulseBg',
        },
        '& brick-icon-v2 div': {
          '--brick--pulseColor70': '$colors$pillCustomOneFilledPulseStartBg',
          '--brick--pulseColor0': '$colors$pillCustomOneFilledPulseEndBg',
        },
      },
    },
    {
      skin: 'custom-two',
      filled: false,
      css: {
        color:
          'var(--custom-background-color-two-front, $colors$pillCustomTwoFg)',
        '& svg': {
          color: '$pillCustomTwoPulseBg',
        },
        '& brick-icon-v2 div': {
          '--brick--pulseColor70': '$colors$pillCustomTwoPulseStartBg',
          '--brick--pulseColor0': '$colors$pillCustomTwoPulseEndBg',
        },
      },
    },
    {
      skin: 'custom-two',
      filled: true,
      css: {
        backgroundColor:
          'var(--custom-background-color-two, $colors$pillCustomTwoFilledBg)',
        color:
          'var(--custom-background-color-two-front, $colors$pillCustomTwoFilledFg)',
        '& brick-icon-v2 div': {
          '--brick--pulseColor70': '$colors$pillCustomTwoFilledPulseStartBg',
          '--brick--pulseColor0': '$colors$pillCustomTwoFilledPulseEndBg',
        },
        '& svg': {
          color: '$pillCustomTwoFilledPulseBg',
        },
      },
    },
    {
      skin: 'custom-three',
      filled: false,
      css: {
        color:
          'var(--custom-background-color-three-front, $colors$pillCustomThreeFg)',
        '& svg': {
          color: '$pillCustomThreeFilledPulseBg',
        },
        '& brick-icon-v2 div': {
          '--brick--pulseColor70': '$colors$pillCustomThreeFilledPulseStartBg',
          '--brick--pulseColor0': '$colors$pillCustomThreeFilledPulseEndBg',
        },
      },
    },
    {
      skin: 'custom-three',
      filled: true,
      css: {
        color:
          'var(--custom-background-color-three-front, $colors$pillCustomThreeFilledFg)',
        backgroundColor:
          'var(--custom-background-color-three, $colors$pillCustomThreeFilledBg)',
        '& brick-icon-v2 div': {
          '--brick--pulseColor70': '$colors$pillCustomThreeFilledPulseStartBg',
          '--brick--pulseColor0': '$colors$pillCustomThreeFilledPulseEndBg',
        },
        '& svg': {
          color: '$pillCustomThreeFilledPulseBg',
        },
      },
    },
    {
      skin: 'highlight',
      filled: false,
      css: {
        color: '$pillHighlightFg',
        '& svg': {
          color: '$pillHighlightPulseBg',
        },
        '& brick-icon-v2 div': {
          '--brick--pulseColor70': '$colors$pillHighlightPulseStartBg',
          '--brick--pulseColor0': '$colors$pillHighlightPulseEndBg',
        },
      },
    },
    {
      skin: 'highlight',
      filled: true,
      css: {
        backgroundColor: '$pillHighlightFilledBg',
        color: '$pillHighlightFilledFg',
        '& brick-icon-v2 div': {
          '--brick--pulseColor70': '$colors$pillHighlightFilledPulseStartBg',
          '--brick--pulseColor0': '$colors$pillHighlightFilledPulseEndBg',
        },
        '& svg': {
          color: '$pillHighlightFilledPulseBg',
        },
      },
    },
    {
      skin: 'opinion',
      filled: false,
      css: {
        color: 'inherit',
        '& svg': {
          color: '$pillOpinionPulseBg',
        },
        '& brick-icon-v2 div': {
          '--brick--pulseColor70': '$colors$pillOpinionPulseStartBg',
          '--brick--pulseColor0': '$colors$pillOpinionPulseEndBg',
        },
      },
    },
    {
      skin: 'opinion',
      filled: true,
      css: {
        backgroundColor:
          'var(--opinion-background-color, $colors$pillOpinionFilledBg)',
        color: 'var(--opinion-color-front, $color$pillOpinionFilledFg)',
        '& brick-icon-v2 div': {
          '--brick--pulseColor70': '$colors$pillOpinionFilledPulseStartBg',
          '--brick--pulseColor0': '$colors$pillOpinionFilledPulseEndBg',
        },
        '& svg': {
          color: '$pillOpinionFilledPulseBg',
        },
      },
    },
    {
      filled: false,
      skin: 'sport',
      css: {
        color: '$pillSportBreakingFg',
        '& svg': {
          color: '$pillSportPulseBg',
        },
        '& brick-icon-v2 .svg-wrap': {
          '--brick--pulseColor70': '$colors$pillSportPulseStartBg',
          '--brick--pulseColor0': '$colors$pillSportPulseEndBg',
        },
      },
    },
    {
      filled: true,
      skin: 'sport',
      css: {
        backgroundColor: '$pillSportFilledBreakingBg',
        color: '$pillSportFilledBreakingFg',
        '& svg': {
          color: '$pillSportFilledPulseBg',
        },
        '& brick-icon-v2 div': {
          '--brick--pulseColor70': '$colors$pillSportFilledPulseStartBg',
          '--brick--pulseColor0': '$colors$pillSportFilledPulseEndBg',
        },
      },
    },
    {
      filled: false,
      skin: 'betting',
      css: {
        color: '$pillBettingBreakingFg',
        '& svg': {
          color: '$pillBettingPulseBg',
        },
        '& brick-icon-v2 .svg-wrap': {
          '--brick--pulseColor70': '$colors$pillBettingPulseStartBg',
          '--brick--pulseColor0': '$colors$pillBettingPulseEndBg',
        },
      },
    },
    {
      filled: true,
      skin: 'betting',
      css: {
        backgroundColor: '$pillBettingFilledBreakingBg',
        color: '$pillBettingFilledBreakingFg',
        '& svg': {
          color: '$pillBettingFilledPulseBg',
        },
        '& brick-icon-v2 div': {
          '--brick--pulseColor70': '$colors$pillBettingFilledPulseStartBg',
          '--brick--pulseColor0': '$colors$pillBettingFilledPulseEndBg',
        },
      },
    },
    {
      skin: 'commercial',
      filled: false,
      css: {
        color: '$pillCommercialFg',
        '& svg': {
          color: '$pillCommercialPulseBg',
        },
        '& brick-icon-v2 div': {
          '--brick--pulseColor70': '$colors$pillCommercialPulseStartBg',
          '--brick--pulseColor0': '$colors$pillCommercialPulseEndBg',
        },
      },
    },
    {
      skin: 'commercial',
      filled: true,
      css: {
        backgroundColor: '$pillCommercialFilledBg',
        color: '$pillCommercialFilledFg',
        '& brick-icon-v2 div': {
          '--brick--pulseColor70': '$colors$pillCommercialFilledPulseStartBg',
          '--brick--pulseColor0': '$colors$pillCommercialFilledPulseEndBg',
        },
        '& svg': {
          color: '$pillCommercialFilledPulseBg',
        },
      },
    },
    {
      skin: 'shopping',
      filled: false,
      css: {
        color: '$pillShoppingFg',
        '& svg': {
          color: '$pillShoppingPulseBg',
        },
        '& brick-icon-v2 div': {
          '--brick--pulseColor70': '$colors$pillShoppingPulseStartBg',
          '--brick--pulseColor0': '$colors$pillShoppingPulseEndBg',
        },
      },
    },
    {
      skin: 'shopping',
      filled: true,
      css: {
        backgroundColor: '$pillShoppingFilledBg',
        color: '$pillShoppingFilledFg',
        '& brick-icon-v2 div': {
          '--brick--pulseColor70': '$colors$pillShoppingFilledPulseStartBg',
          '--brick--pulseColor0': '$colors$pillShoppingFilledPulseEndBg',
        },
        '& svg': {
          color: '$pillShoppingFilledPulseBg',
        },
      },
    },
  ],
});

export function getStyleClasses({ version, filled, skin }: PillStyleVariants) {
  let styles = `${pillStyle({ version, filled })}`;
  if (version === 'breaking') {
    return (styles += ` ${pillBreakingStyle({ skin, filled })}`);
  }

  if (filled) {
    if (version === 'alt' || version === 'untold') {
      return (styles += ` ${versionSpecificColors({ version })}`);
    }

    if (skin) {
      return (styles += ` ${pillSkinFilledStyle({ skin })}`);
    }
  }

  return (styles += ` ${pillSkinStyle({ skin })}`);
}
