import { css } from '../../styles';

const customOneDefaultColors = {
  backgroundColor:
    'var(--custom-background-color-one, $colors$teaserFooterCustomOneBg)',
  color: 'var(--custom-background-color-one-front, $supportiveCustomOneFg)',
};

const customTwoDefaultColors = {
  backgroundColor:
    'var(--custom-background-color-two ,$colors$teaserFooterCustomTwoBg)',
  color: 'var(--custom-background-color-two-front, $supportiveCustomTwoFg)',
};

const customThreeDefaultColors = {
  backgroundColor:
    'var(--custom-background-color-three ,$colors$teaserFooterCustomThreeBg)',
  color: 'var(--custom-background-color-three-front, $supportiveCustomThreeFg)',
};

const blackDefaultColors = {
  backgroundColor: '$teaserFooterBlackBg',
  color: '$supportiveBlackFg',
};

const highlightDefaultColors = {
  backgroundColor: '$supportiveHighlightBg',
  color: '$supportiveHighlightFg',
};

const containerPaddingM = {
  padding:
    '$teaserFooterTipusTopM $teaserFooterTipusRightM $teaserFooterTipusBottomM $teaserFooterTipusLeftM',
};

const containerPaddingL = {
  padding:
    '$teaserFooterTipusTopL $teaserFooterTipusRightL $teaserFooterTipusBottomL $teaserFooterTipusLeftL',
};

export const footerContainerStyle = css({
  boxSizing: 'border-box',
  // If teaser is in a group and has no skin
  '.optimus-complex-front article:not(.skin) &': {
    color: 'inherit',
    backgroundColor: 'inherit',
  },

  gap: '$teaserStackBM',

  '@bp532': {
    gap: '$teaserStackBL',
  },

  '@bp680': {
    gap: '$teaserStackBL',
  },

  variants: {
    hasByline: {
      true: {
        'brick-carousel &': {
          paddingBottom: '$x2',
        },
      },
      false: {},
    },
    skin: {
      sport: {
        backgroundColor: '$supportiveSportBg',
        color: '$supportiveSportFg',
      },
      none: {
        color: '$supportiveNoneFg',
        backgroundColor: '$teaserFooterNoneBg',
      },
      black: {},
      'custom-one': {},
      'custom-two': {},
      'custom-three': {},
      highlight: {},
    },
    theme: {
      alt: {},
      alfa: {},
      bravo: {},
      charlie: {},
      nettavisen: {},
    },
  },

  compoundVariants: [
    {
      theme: 'bravo',
      skin: 'none',
      css: {
        ...containerPaddingM,
        '@bp532': {
          ...containerPaddingL,
        },
        '@bp680': {
          ...containerPaddingL,
        },
      },
    },
    {
      theme: 'bravo',
      skin: 'black',
      css: {
        ...blackDefaultColors,
        ...containerPaddingM,
        '@bp532': {
          ...containerPaddingL,
        },
        '@bp680': {
          ...containerPaddingL,
        },
      },
    },
    {
      theme: 'alfa',
      skin: 'custom-one',
      hasByline: false,
      css: {
        ...customOneDefaultColors,
      },
    },
    {
      theme: 'bravo',
      skin: 'custom-one',
      css: {
        ...customOneDefaultColors,
      },
    },
    {
      theme: 'charlie',
      skin: 'custom-one',
      hasByline: false,
      css: {
        ...customOneDefaultColors,
      },
    },
    {
      theme: 'nettavisen',
      skin: 'custom-one',
      css: {
        backgroundColor: '$teaserFooterCustomOneBg',
        color: '$supportiveCustomTwoFg',
        ...containerPaddingM,
        '@bp532': {
          ...containerPaddingL,
        },
        '@bp680': {
          ...containerPaddingL,
        },
      },
    },
    {
      theme: 'alfa',
      skin: 'custom-two',
      hasByline: false,
      css: {
        ...customTwoDefaultColors,
      },
    },
    {
      theme: 'bravo',
      skin: 'custom-two',
      css: {
        ...customTwoDefaultColors,
      },
    },
    {
      theme: 'charlie',
      skin: 'custom-two',
      hasByline: false,
      css: {
        ...customTwoDefaultColors,
      },
    },
    {
      theme: 'alfa',
      skin: 'custom-three',
      hasByline: false,
      css: {
        ...customThreeDefaultColors,
      },
    },
    {
      theme: 'charlie',
      skin: 'custom-three',
      hasByline: false,
      css: {
        ...customThreeDefaultColors,
      },
    },
    {
      theme: 'alfa',
      skin: 'black',
      hasByline: false,
      css: {
        ...blackDefaultColors,
      },
    },
    {
      theme: 'charlie',
      skin: 'black',
      hasByline: false,
      css: {
        ...blackDefaultColors,
      },
    },
    {
      theme: 'alfa',
      skin: 'highlight',
      hasByline: false,
      css: {
        ...highlightDefaultColors,
      },
    },
    {
      theme: 'charlie',
      skin: 'highlight',
      hasByline: false,
      css: {
        ...highlightDefaultColors,
      },
    },
    {
      theme: 'nettavisen',
      skin: 'custom-two',
      css: {
        backgroundColor: '$teaserFooterCustomTwoBg',
        color: '$supportiveCustomTwoFg',
        ...containerPaddingM,
        '@bp532': {
          ...containerPaddingL,
        },
        '@bp680': {
          ...containerPaddingL,
        },
      },
    },
  ],
});
