import { BrickElement, defineCustomElement } from '@amedia/brick-template';
import { AmediaImageClient } from '@amedia/brick-image';
import '@amedia/brick-countdown';
import '@amedia/brick-pill';
import '@amedia/brick-icon';
import '@amedia/brick-player';
import '@amedia/brick-avatar';
import type { Design } from '@amedia/brick-tokens';

import { VideoPreview } from './components/image/video-preview';
import { brickTeaserTemplate } from './template';
import { decodeTeaserData } from './utils/base64';
import { mapToTeaser } from './api/teaserApi';
import type {
  RenderOptions,
  Teaser,
  TeaserApi,
  TeaserVersion,
  ToggleOptions,
} from './types';
import { brickTeaserElement } from './styles';
import { BreakingText } from './components/breaking-text/breaking-text';

function getOptionKey(attrName, regex) {
  if (!attrName) {
    return;
  }
  const key = attrName
    .replace(regex, '')
    .split('-')
    .map((part, index) => {
      if (index === 0) {
        // dont capitalize first letter
        return part;
      }
      return part[0].toUpperCase() + part.slice(1);
    })
    .join('');
  return key;
}

@defineCustomElement({
  selector: 'brick-teaser-v15',
})
export class BrickTeaser extends BrickElement {
  data: Teaser;
  renderoptions?: RenderOptions;
  toggleOptions: ToggleOptions;
  linkElement?: HTMLAnchorElement;

  constructor(data: Teaser) {
    super();
    this.data = data;
    this.toggleOptions = {};

    this.showOverlayOnClick = this.showOverlayOnClick.bind(this);
  }

  async connectedCallback() {
    if (this.data === undefined) {
      this.setData();
    }

    if (!this.isRendered) {
      this.classList.add(`${brickTeaserElement}`);
    }

    super.connectedCallback();

    if (this['dataAmediaReferrer']) {
      const referralLink = this.querySelectorAll('a');
      const url = new URL(referralLink[0].href);
      url.searchParams.set('amedia_referrer', `${window.location.host}`);
      referralLink[0].href = url.toString();
    }

    this.addEventListener(
      'brick-teaser:attribute',
      this.attributeHandler.bind(this)
    );

    this.linkElement =
      this.querySelector('article a[itemprop="url"]') || undefined;

    this.overlayCheck();
  }

  async disconnectedCallback() {
    this.removeEventListener(
      'brick-teaser:attribute',
      this.attributeHandler.bind(this)
    );

    this.linkElement?.removeEventListener('click', this.showOverlayOnClick);
  }

  //event
  attributeHandler(e) {
    const { key, keyValue, attributeName, attributeValue } = e.detail;
    //Validating that the event is coming from the same element
    if (!key || !keyValue) {
      this.setAttribute(attributeName, attributeValue);
    }
    if (this.getAttribute(key) === keyValue) {
      this.setAttribute(attributeName, attributeValue);
    }
  }

  setData() {
    const teaserData: TeaserApi =
      this.children[0]?.getAttribute('data-type') === 'teaser'
        ? JSON.parse(this.children[0].innerHTML)
        : this.dataset.teaser
        ? decodeTeaserData(this.dataset.teaser) // Temporary backwards compability
        : undefined;

    if (teaserData) {
      this.data = mapToTeaser({
        ...teaserData,
        size: this.dataset.size,
        version: this.dataset.version as TeaserVersion,
        theme: this.dataset.theme as Design,
      });
    }
    this.renderoptions = [...this.attributes]
      .filter((attr) => attr.name.startsWith('renderoptions-'))
      .reduce(
        (result, attr) => ({
          ...result,
          [getOptionKey(attr.name, /^renderoptions-/)]: attr.value,
        }),
        {}
      );

    if (this.data?.toggleOptions) {
      this.toggleOptions = this.data.toggleOptions;
    }
    if (this.data?.externalId) {
      this['dataExternalId'] = this.data.externalId;
    }
    if (this.data?.amediaReferrer) {
      this['dataAmediaReferrer'] = this.data.amediaReferrer;
    }
    if (this.data?.teaserType) {
      this['dataTeaserType'] = this.data.teaserType;
    }
    if (this.data?.id) {
      this['dataId'] = this.data.id;
    }
    if (this.data?.videoMeta?.playOnFront) {
      this['dataVideoPlay'] = this.data.videoMeta.playOnFront;
    }
  }

  setToggleOptions() {
    const toggleOptions = [...this.attributes]
      .filter((attr) => attr.name.startsWith('toggle-'))
      .reduce(
        (result, attr) => ({
          ...result,
          [getOptionKey(attr.name, /^toggle-/)]: attr.value,
        }),
        {}
      );
    this.toggleOptions = toggleOptions;
  }

  overlayCheck() {
    if (this.linkElement) {
      if (!this.iOS) {
        return;
      }

      if (
        !this.popcornOverlayElement ||
        this['dataTeaserType'] !== 'video' ||
        this['dataVideoPlay'] === 'true' ||
        this['dataHasAccess'] !== 'true'
      ) {
        return;
      }

      this.linkElement?.addEventListener('click', this.showOverlayOnClick);
    }
  }

  showOverlayOnClick(event) {
    if (!this.popcornOverlayElement && !this.linkElement) {
      return;
    }
    event.preventDefault();

    const articleid = `${
      this['dataId'] ||
      this.querySelector('article meta[itemprop="identifier"]')?.getAttribute(
        'content'
      )
    }`.replace(/-[a-z]$/, '');

    const body = document.querySelector('body');
    if (body) body.style.overflow = 'hidden';

    this.popcornOverlayElement?.setAttribute('param-article-id', articleid);
    this.popcornOverlayElement?.setAttribute('class', 'active');

    window.history.pushState({}, '', this.linkElement?.getAttribute('href'));

    const overlayEvent = new CustomEvent('popcorn:overlay', {
      bubbles: true,
      cancelable: true,
      composed: false,
      detail: {
        articleid: articleid,
      },
    });

    this.dispatchEvent(overlayEvent);
  }

  static get observedAttributes() {
    return [
      'data-countdown-done',
      'data-amedia-referrer',
      'data-breaking-news-text',
      'data-title',
      'data-has-access',
      'data-video-play',
    ];
  }

  //Attributes should almost always have mirrored properties. We don't just use the observedAttributes array as the source for this integration, because you may often want to write custom getter/setter functions for some attributes
  static get mirroredProps() {
    return [
      'data-external-id',
      'data-countdown-done',
      'data-amedia-referrer',
      'data-theme',
      'data-teaser-type',
      'data-id',
      'data-has-access',
      'data-video-play',
    ];
  }

  get HTML() {
    return brickTeaserTemplate(this.data, this.renderoptions, this.isRendered);
  }

  updateBreakingText(newValue) {
    if (newValue) {
      const pills = this.querySelectorAll('brick-pill');
      const breakingText = this.querySelectorAll('breaking-text');
      const elements = [...pills, ...breakingText].filter(Boolean);
      elements.forEach((element) =>
        element.dispatchEvent(
          new CustomEvent('brick-teaser:breakingText', {
            bubbles: false,
            cancelable: true,
            composed: false,
            detail: newValue,
          })
        )
      );
    }
  }

  updateCountdown(newValue, teaserTheme) {
    if (!this.data) {
      this.setToggleOptions();
    }

    if (newValue === 'true' && this.toggleOptions?.countdownText) {
      const teaserTitle = this.querySelector('[itemprop="headline"]');
      const teaserChild = teaserTitle?.lastChild;
      const newText =
        this.toggleOptions.countdownText ||
        this.getAttribute('toggle-countdown-text');
      if (teaserTitle && teaserChild && newText) {
        const newNode = document.createElement('span');
        newNode.innerHTML = `<breaking-text data-theme="${teaserTheme}" data-text="${newText}" data-color="red"></breaking-text>`;
        newNode.style.marginInlineEnd = '5px';
        teaserTitle.insertBefore(newNode, teaserChild);
      }
    }
  }

  updateTitle(newValue) {
    if (newValue) {
      const teaserTitle = this.querySelector('[itemprop="titleText"]');
      if (teaserTitle) {
        teaserTitle.innerHTML = newValue;
      }
    }
  }

  renderBrickPlayer() {
    const templateElement = this.querySelector('template');
    if (templateElement) {
      const playerClone = templateElement.content.cloneNode(true);
      this.replaceChild(playerClone, this.getElementsByTagName('article')[0]);
    }
  }

  attributeChangedCallback(attr: string, val: string, newValue: string) {
    if (attr && val === newValue) return;
    // const propName = this['attrToProp'][attr];
    switch (attr) {
      case 'data-breaking-news-text':
        this.updateBreakingText(newValue);
        break;
      case 'data-countdown-done':
        this.updateCountdown(newValue, this.dataset.theme);
        break;
      case 'data-title':
        this.updateTitle(newValue);
        break;
      case 'data-has-access':
        if (newValue === 'true') {
          if (this['dataVideoPlay'] === 'true') {
            this.renderBrickPlayer();
          } else {
            this.overlayCheck();
          }
        }

      default:
      // this[propName] = newValue;
    }
  }

  get popcornOverlayElement() {
    return (
      document.querySelector('amedia-include#popcorn-overlay') || undefined
    );
  }

  get iOS() {
    if (this.getAttribute('data-useragent-ios')) return true;

    const iOS = /iP(ad|hone|od)/.test(navigator.userAgent);
    const ua = navigator.userAgent.match(/OS (\d+)_(\d+)_?(\d+)?/);
    const uaVersion = ua && parseInt(ua[1], 10) >= 16;

    return iOS && uaVersion;
  }
}

if (typeof customElements !== 'undefined') {
  if (!customElements.get('amedia-img')) {
    customElements.define('amedia-img', AmediaImageClient);
  }
  if (!customElements.get('video-preview')) {
    customElements.define('video-preview', VideoPreview);
  }
  if (!customElements.get('breaking-text')) {
    customElements.define('breaking-text', BreakingText);
  }
}
