import type { Design } from '@amedia/brick-tokens/themes';

import esc from '../utils/escape-html';
import { RenderOptions, Skin, Teaser, TeaserType } from '../types';

import { getLogData } from './adp';
import { teaserStyle } from './teaser-style.js';
import { getTemplateStructure } from './teaserSwitch';
import { playerTemplate } from './templates/playerTemplate.js';
import { skinColorStyle } from './skinStyles';

function getClasses(data: Teaser, minusMargin: boolean): string {
  const {
    body: { backgroundColor = '', marker, hasImage },
    teaserType,
    themeClass,
    premium,
    isPremiumAll,
  } = data;
  const typeClass = teaserType === 'teaser' ? 'none' : teaserType;
  const backgroundColorClass = backgroundColor ? 'skin' : '';
  const imageClass = hasImage ? 'has-image' : '';
  const minusMarginClass = minusMargin ? 'minus-margin' : '';
  const markerClass = marker ? 'marker' : '';
  const premiumClass = premium || isPremiumAll ? 'premium' : '';

  return `
    ${typeClass} ${backgroundColorClass} ${themeClass} ${imageClass} ${minusMarginClass} ${markerClass} ${premiumClass} ${esc(
    backgroundColor
  )}`;
}

function getPadding({
  teaserType,
  skin,
  marker,
  hasImage,
  hasByline,
  theme,
  isContentMarketing,
}: {
  teaserType: TeaserType;
  skin: Skin;
  marker: boolean;
  hasImage: boolean;
  hasByline: boolean;
  theme: Design;
  isContentMarketing: boolean;
}) {
  //After each teaser type is extracted as a standalone buildstone with its own css and markup,
  //we may remove the padding and most of the styling from this element alltogether
  if ((hasByline && theme === 'alfa') || (hasByline && theme === 'charlie')) {
    return { small: '0', large: '0' };
  }
  //TODO: we make a token for this so we can same token for all themes, small/large
  //Q: is the value for teaserNoImageInsetM wrong for Nettavisen?
  if (!hasImage && !marker) {
    if (theme === 'nettavisen') {
      return {
        small:
          'var(--brick-space-teaserYInsetM) var(--brick-space-teaserXInsetM)',
        large: 'var(--brick-space-teaserNoImageInsetL)',
      };
    }

    return {
      small: 'var(--brick-space-teaserNoImageInsetM)',
      large: 'var(--brick-space-teaserNoImageInsetL)',
    };
  }

  if (teaserType === 'feature' || teaserType === 'sport') {
    return { small: '0', large: '0' };
  }

  if (marker) {
    return {
      small:
        'var(--brick-space-teaserYInsetM) var(--brick-space-teaserXInsetM)',
      large:
        'var(--brick-space-teaserYInsetL) var(--brick-space-teaserXInsetL)',
    };
  }

  if (skin !== 'none' && !isContentMarketing) {
    return {
      small: 'var(--brick-space-teaserSkinInsetM)',
      large: 'var(--brick-space-teaserSkinInsetL)',
    };
  }

  return {
    small: 'var(--brick-space-teaserYInsetM) var(--brick-space-teaserXInsetM)',
    large: 'var(--brick-space-teaserYInsetL) var(--brick-space-teaserXInsetL)',
  };
}

export default function teaserTemplate(
  data: Teaser,
  options: RenderOptions = {}
) {
  const {
    size,
    version,
    skin,
    body: { marker, premiumVersion, teaserType, hasByline, title, hasImage },
    theme,
    isContentMarketing,
    videoMeta,
  } = data;
  const {
    useAutolayout = false,
    minusmargin = false,
    removeLogScope = false,
    aoiBeta = false,
  } = options;

  if (data.version === 'imageLeft' && data.teaserType === 'feature') {
    return '';
  }
  const renderBubble = hasByline && !!(theme === 'alfa' || theme === 'charlie');
  const skinStyles = renderBubble
    ? ''
    : skinColorStyle({ skin, image: hasImage, teaserType });

  const styles = teaserStyle({
    size,
    version,
    image: hasImage,
    theme,
    marker,
  });

  const { logAttributes = '', logDataMarkup = '' } = getLogData(
    data,
    removeLogScope
  );

  const themeStructure = getTemplateStructure({
    data,
    useAutolayout,
    aoiBeta,
  });

  const teaserPadding = getPadding({
    teaserType,
    skin,
    marker,
    hasImage,
    hasByline,
    theme,
    isContentMarketing,
  });

  /*
  If video teaser and it is set to play in the teaser itself on the frontpage,
  then return brick-player instead of normal teaser markup.
  */
  const renderInlineVideo =
    teaserType === 'video' && videoMeta?.playOnFront === true;

  let playVideoTemplate = '';

  if (renderInlineVideo) {
    playVideoTemplate = playerTemplate({ title, videoMeta }) || '';
    if (!premiumVersion && playVideoTemplate) {
      return playVideoTemplate;
    } else {
      playVideoTemplate = `<template>${playVideoTemplate}</template>`;
    }
  }

  return `
      <article
        ${logAttributes}
        class="teaser_container
        ${getClasses(data, minusmargin)}
        ${skinStyles}
        ${styles}
        "
        style="--brick-teaser-padding-small-screen:${
          teaserPadding.small
        }; --brick-teaser-padding-large-screen:${teaserPadding.large}"
        data-theme="${theme}">
         ${logDataMarkup}
         ${themeStructure}
      </article>
      ${playVideoTemplate}
`;
}
