import { css } from '@amedia/brick-tokens';

//the object bodyLayout are used to generate css for inline injection by scoponok
export const sportBodyLayout = {
  variants: {
    version: {
      imageLeft: {
        columnGap: 0,
        rowGap: '$teaserStackBM',
        $$areas: '"image title"',
        gridTemplateColumns: 'calc(34% + 3%) 1fr',
        gridFlow: 'row',
        position: 'relative',

        '&.mirror': {
          $$areas: '"title image"',
          gridTemplateColumns: '1fr calc(34% + 3%)',
        },
        '&.mirror.pills': {
          $$areas: '"pills image" "title image"',
        },

        '[data-theme="alfa"] &.pills .sport_title': {
          paddingTop: 0,
          '@bp532': {
            paddingTop: 0,
          },
        },

        '[data-theme="bravo"] &.premium': {
          $$areas: '"image title" "image premium"',
        },
        '[data-theme="bravo"] &.pills': {
          $$area: '"image pills" "image title"',
        },
        '[data-theme="bravo"] &.premium.pills': {
          $$areas: '"image pills" "image title" "image premium"',
          gridTemplateRows: 'auto 1fr auto',
        },
        '[data-theme="bravo"] &.pills .sport_title': {
          paddingTop: '$teaserStackBM',
          '@bp532': {
            paddingTop: '$teaserStackBL',
          },
        },

        '[data-theme="charlie"] &.premium': {
          $$areas: '"image title premium"',
          gridTemplateColumns: 'calc(34% + 3%) 1fr auto',
          rowGap: '$teaserStackBM',
          columnGap: 0,
        },
        '[data-theme="charlie"] &.premium.pills': {
          $$areas: '"image pills pills" "image title premium"',
          gridTemplateColumns: 'calc(34% + 3%) 1fr auto',
        },
        '[data-theme="charlie"] &.pills .sport_title': {
          paddingTop: 0,
          '@bp532': {
            paddingTop: 0,
          },
        },

        '[data-theme="nettavisen"] &.pills .sport_title': {
          paddingTop: 0,
          '@bp532': {
            paddingTop: 0,
          },
        },

        '@bp532': {
          rowGap: '$teaserStackBL',
          columnGap: 0,
        },

        '& .teaser_image': {
          height: '100%',
        },
        '& .sport-pills': {
          gridArea: 'pills',
          zIndex: 'initial',
          alignSelf: 'start',
          justifySelf: 'start',
        },
        '[data-theme="alfa"] & .premium-wrapper, [data-theme="nettavisen"] & .premium-wrapper':
          {
            padding: '$x2',
          },
      },
    },
  },
};

//TODO: create tokens for all theme specific variants

export const imageSportLayoutStyle = css({
  $$areas: '"image" "title"',
  gridTemplateAreas: '$$areas',
  display: 'grid',
  gridTemplateRows: 'auto',
  columnGap: '$teaserStackAM',
  gridTemplateColumns: '1fr',
  gridAutoRows: '1fr auto',
  rowGap: '$teaserStackAM',

  '[data-theme="bravo"] &.premium': {
    $$areas: '"image" "title" "premium"',
  },
  '[data-theme="bravo"] &.pills .sport_title': {
    paddingTop: 'calc($space$teaserStackAM + $space$x3)',
    '@bp532': {
      paddingTop: 'calc($space$teaserStackAL + $space$x3)',
    },
  },

  '[data-theme="charlie"] &.premium': {
    $$areas: '"image image" "title premium"',
  },

  '@bp532': {
    columnGap: '$teaserStackAL',
  },

  '& .teaser_image': {
    gridArea: 'image',
  },

  '& .sport-pills': {
    gridArea: 'image',
    zIndex: '2',
    alignSelf: 'end',
    justifySelf: 'end',
  },

  '[data-theme="alfa"] & .premium-wrapper, [data-theme="nettavisen"] & .premium-wrapper':
    {
      padding: '$x3',
    },

  variants: {
    version: {
      default: {},
      imageLeft: {
        ...sportBodyLayout.variants.version.imageLeft,
      },
    },
  },
});

export const imageWrapper = css({
  gridArea: 'image',
  position: 'relative',
});

export const gradientStyle = css({
  background: '$supportiveSportGradient',
  position: 'absolute',
  inset: 0,
});

export const noImageSportLayoutStyle = css({
  $$areas: '"title"',
  gridTemplateAreas: '$$areas',
  display: 'grid',
  gridTemplateColumns: '1fr',
  gridTemplateRows: 'auto 1fr',
  padding: '$teaserContentYInsetM $teaserContentXInsetM',
  rowGap: '$teaserStackBM',

  '@bp532': {
    padding: '$teaserContentYInsetL $teaserContentXInsetL',
    rowGap: '$teaserStackBL',
  },

  variants: {
    theme: {
      alfa: {},
      bravo: {},
      charlie: {},
      nettavisen: {},
      alt: {},
    },
    pills: {
      true: {},
      false: {},
    },
    premium: {
      true: {},
      false: {},
    },
  },
  compoundVariants: [
    {
      pills: false,
      premium: true,
      theme: 'alfa',
      css: {
        $$areas: '"title premium"',
      },
    },
    {
      pills: true,
      premium: true,
      theme: 'alfa',
      css: {
        $$areas: '"pills ." "title premium"',
      },
    },
    {
      pills: false,
      premium: true,
      theme: 'bravo',
      css: {
        $$areas: '"title title" ". premium"',
        '[data-group="ghost"] .solo-group &': {
          $$areas: '"title premium"',
        },
      },
    },
    {
      pills: true,
      premium: true,
      theme: 'bravo',
      css: {
        $$areas: '"pills pills" "title title" ". premium"',
      },
    },
    {
      pills: false,
      premium: true,
      theme: 'charlie',
      css: {
        $$areas: '"title premium"',
      },
    },
    {
      pills: true,
      premium: true,
      theme: 'charlie',
      css: {
        $$areas: '"pills ." "title premium"',
      },
    },
    {
      pills: true,
      theme: 'nettavisen',
      css: {
        $$areas: '"pills" "title"',
      },
    },
  ],
});

export const sportTeaserStyle = css({
  container: 'teaser-content / inline-size',
});

export const premiumWrapper = css({
  display: 'flex', //the flex container will "hug" its content
  '--b-premium-color-bg': 'transparent',
  '--b-premium-color-fg': '$colors$supportiveSportFg',

  variants: {
    theme: {
      alfa: {},
      bravo: {
        paddingTop: 'calc($space$teaserStackCM - $space$teaserStackBM)',
        '@bp532': {
          paddingTop: 'calc($space$teaserStackCL - $space$teaserStackBL)',
        },
      },
      charlie: {},
      nettavisen: {},
      alt: {},
    },
    hasImage: {
      true: {
        'brick-carousel &': {
          padding: '$space$x2s',
        },
      },
      false: { gridArea: 'premium', alignSelf: 'end' },
    },
  },
  compoundVariants: [
    {
      theme: 'alfa',
      hasImage: true,
      css: {
        gridArea: 'image',
        zIndex: '2',
        alignSelf: 'start',
        justifySelf: 'end',
        '--b-premium-color-bg': '$colors$premiumIconBg',
      },
    },
    {
      theme: 'nettavisen',
      hasImage: true,
      css: {
        gridArea: 'image',
        zIndex: '2',
        alignSelf: 'start',
        justifySelf: 'end',
        '--b-premium-color-bg': '$colors$premiumIconBg',
      },
    },
    {
      theme: 'bravo',
      hasImage: true,
      css: {
        gridArea: 'premium',
        justifySelf: 'end',
        padding: '0 $x2 $x2 0',
      },
    },
    {
      theme: 'charlie',
      hasImage: true,
      css: {
        gridArea: 'premium',
        alignSelf: 'end',
        padding: '0 $x2 $x2 0',
      },
    },
  ],
});

export const pillContainer = css({
  height: 'max-content',
  variants: {
    hasImage: {
      true: { padding: '$x3 $x3 0 $x3' },
      false: { gridArea: 'pills' },
    },
  },
  'brick-carousel &': {
    padding: '$x2s $x2s 0 $x2s',
    '@bp532': {
      padding: '$x2 $x2 0 $x2',
    },
  },
});

export const titleWrapper = css({
  $$paddingTop: '$space$teaserStackAM',
  padding: '$$paddingTop $x3 $x3 $x3',
  gridArea: 'title',

  '@bp532': {
    $$paddingTop: '$space$teaserStackAL',
  },

  'brick-carousel &': {
    padding: '$x2s',
  },

  variants: {
    hasImage: {
      true: {},
      false: { padding: 0 },
    },
    pills: {
      true: {},
      false: {},
    },
  },
  compoundVariants: [
    {
      pills: false,
      hasImage: true,
      css: {
        $$paddingTop: '$space$x3',
      },
    },
  ],
});

export const avatarOverlay = css({
  gridArea: 'image',
  zIndex: '2',
  alignSelf: 'end',
  display: 'flex',
  flexDirection: 'row',
  paddingLeft: '$x3',

  'brick-carousel &': {
    paddingLeft: '$x2s',
  },

  '@container (inline-size < 200px)': {
    display: 'none',
  },

  '--b-avatar-color-fg': 'var(--brick-colors-baseNeutral00)',
  '--b-avatar-color-border': 'var(--brick-colors-supportiveSportBg)',
  '--b-avatar-color-shape': 'var(--brick-colors-baseNeutral600)',
  '--b-avatar-padding': 'var(--brick-sizes-x1)',
});
